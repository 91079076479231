<template>
  <div>
    <b-modal
      id="modal-edit-id-member"
      ref="modal-edit-id-member"
      title="แก้ไขข้อมูลบัตรประชาชน"
      no-close-on-backdrop
      hide-footer
    >
      <b-form @submit.prevent="editMember">
        <b-button
          :disabled="loading"
          class="mb-2"
          block
          variant="relief-success"
          @click="read_id_card()"
        >
          <b-spinner small v-if="loading" /> อ่านบัตร
        </b-button>

        <!-- คำนำหน้า -->
        <b-form-group label="เลขบัตรประชาชน">
          <b-form-input v-model="form.id_card_id" />
        </b-form-group>

        <!-- คำนำหน้า -->
        <b-form-group label="th_prefix">
          <b-form-input v-model="form.id_th_prefix" />
        </b-form-group>

        <!-- ชื่อ -->
        <b-form-group label="ชื่อ">
          <b-form-input v-model="form.id_th_first_name" />
        </b-form-group>

        <!-- นามสกุล -->
        <b-form-group label="นามสกุล">
          <b-form-input v-model="form.id_th_last_name" />
        </b-form-group>

        <!-- prefix EN -->
        <b-form-group label="en_prefix">
          <b-form-input v-model="form.id_en_prefix" />
        </b-form-group>

        <!-- ชื่อ EN -->
        <b-form-group label="ชื่อ EN">
          <b-form-input v-model="form.id_en_first_name" />
        </b-form-group>

        <!-- นามสกุล EN -->
        <b-form-group label="นามสกุล EN">
          <b-form-input v-model="form.id_en_last_name" />
        </b-form-group>

        <b-form-group label="วันเกิด">
          <b-form-input v-model="form.id_birth_date" />
        </b-form-group>

        <b-form-group label="ที่อยู่">
          <b-form-input v-model="form.id_address" />
        </b-form-group>

        <b-form-group label="วันที่ออกบัตร">
          <b-form-input
            v-model="form.id_issue_date"
            placeholder="2023-06-15T00:00:00"
          />
        </b-form-group>

        <b-form-group label="วันหมดอายุบัตร">
          <b-form-input
            v-model="form.id_expire_date"
            placeholder="2023-06-15T00:00:00"
          />
        </b-form-group>

        <b-form-group label="สถานที่ออกบัตร">
          <b-form-input v-model="form.id_card_issuer" />
        </b-form-group>

        <hr />
        <b-button variant="success" @click.prevent="EditMember">
          แก้ไขข้อมูล
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BCard,
  BFormGroup,
  BForm,
  BFormInput,
  BButton,
  BSpinner,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BModal,
    BForm,
    BButton,
    BFormGroup,
    BSpinner,
    BFormInput,
  },
  props: ["member_id"],
  data() {
    return {
      form: {},
      card_obj: {},
      loading: false,
    };
  },
  created() {
    this.LoadMember();
  },
  methods: {
    read_id_card() {
      this.loading = true;
      this.$http({
        method: "GET",
        url: `http://127.0.0.1:7001/read-id-card`,
      })
        .then((x) => {
          this.card_obj = x.data.data;
          this.form = this.card_obj;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error.message === "Network Error") {
            this.toast_error("ไม่เจอเครื่องอ่านบัตร");
          } else {
            this.toast_error("มีข้อผิดพลาด", error);
          }
        });
    },
    LoadMember() {
      this.$http({
        method: "GET",
        url: `/member/view/` + this.member_id,
        data: this.form,
      })
        .then((x) => {
          this.form = x.data.data;
        })
        .catch((error) => {
          this.toast_error("มีข้อผิดพลาด", error.message);
          console.log(error);
        });
    },
    EditMember() {
      if (
        this.form.id_card_id != "" &&
        this.form.id_card_id != undefined &&
        this.form.id_card_id != null
      ) {
        this.form.id_card_readed = true;
      }
      this.$http({
        method: "PUT",
        url: `/member/${this.member_id}`,
        data: this.form,
      })
        .then((x) => {
          this.$refs["modal-edit-id-member"].hide();
          this.showToast("success", "CheckIcon");
          this.LoadMember();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
