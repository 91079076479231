<template>
  <div>
    <h1>รายรับ</h1>
    <!-- <h4>รายการตัดยอดที่วันรับอุปกรณ์เป็นวันนี้</h4> -->
    <b-card>
      <div class="row">
        <div class="col-12 col-md-7 d-flex flex-column justify-content-end">
          <div class="d-flex align-items-center mb-2">
            <div>
              <b-card-text class="mb-0 mr-2">แสดงตาราง</b-card-text>
              <b-form-checkbox
                checked="true"
                class="custom-control-success"
                name="check-button"
                switch
                v-model="form.show_table"
              />
            </div>
            <div v-if="form.show_table">
              <b-card-text class="mb-0 mr-2">DIFF ONLY</b-card-text>
              <b-form-checkbox
                checked="true"
                class="custom-control-success"
                name="check-button"
                switch
                v-model="form.show_diff"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-5 text-right">
          วันที่
          <date-picker-range @date_picked="date_picked"></date-picker-range>
        </div>
      </div>
    </b-card>
    <div class="row mb-2">
      <div class="col-md-7">
        <account-checklist :form="form" />
      </div>
      <div class="col-md-5">
        <account-seperate :form="form"></account-seperate>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <!-- <account-summary :form="form"></account-summary> -->
      </div>
      <div class="col-md-6"></div>
    </div>

    <b-overlay variant="dark" :show="loading" rounded="sm">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Time</th>
              <th>Order ID</th>
              <th>ข้อมูลลูกค้า</th>
              <th>ชำระรวม</th>
              <th>ค่าวางประกัน</th>
              <th>ค่าเช่า</th>
              <th>ค่าส่ง</th>
              <th>ส่วนลด</th>
              <th>หัก ณ ที่จ่าย</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ (sumPaidRental + sumPaidShipping) | number }}</td>
              <td>{{ sumPaidDeposit | number }}</td>
              <td>{{ sumPaidRental | number }}</td>
              <td>{{ sumPaidShipping | number }}</td>
              <td>{{ sumOrderDiscount | number }}</td>
              <td>{{ sumOrderWithHolding | number }}</td>
            </tr>
            <tr
              v-for="(row, index) in filterTableData"
              :key="index"
              v-if="form.show_table"
            >
              <td>
                {{ row.start_date | day }} - {{ row.start_date | time }} <br />
              </td>
              <td>
                <b-button
                  variant="relief-success"
                  class="btn-icon"
                  @click="open_new(row.order_id)"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
                <br />
                <small>{{ row.order_id_short }}</small> <br />
                <b-badge>{{ row.rental_paid_count }}</b-badge>
              </td>
              <td>
                <div v-if="row.member_detail">
                  <span v-if="row.member_detail.retro_username">
                    ( {{ row.member_detail.retro_username }} ) <br />
                  </span>
                  <span v-else-if="row.member_detail.gen_username">
                    ( {{ row.member_detail.gen_username }} ) <br />
                  </span>

                  {{ row.member_detail.first_name }}
                  {{ row.member_detail.last_name }} <br />
                  {{ row.member_detail.phone }}
                </div>
              </td>
              <td>{{ row.total_paid | number }}</td>
              <td>
                <div v-if="row.deposit_diff > 0 || row.deposit_diff < 0">
                  <b-badge variant="light-danger">
                    {{ row.deposit_paid | number }} /
                    {{ (row.deposit_paid + row.deposit_diff) | number }}
                  </b-badge>
                </div>
                <div v-else>
                  {{ row.deposit_paid | number }}
                </div>
              </td>
              <td>
                <div v-if="row.rental_diff > 0 || row.rental_diff < 0">
                  <b-badge variant="light-danger">
                    {{ row.rental_paid | number }} /
                    {{ row.rental_price | number }}
                  </b-badge>
                </div>
                <div v-else>
                  {{ row.rental_paid | number }}
                </div>
              </td>
              <!-- <td>
              {{ row.rental_paid | number }}
              <div v-if="row.rental_diff >= 1">
                <b-badge variant="light-danger" v-if="row.rental_diff >= 1">
                  {{ row.rental_diff | number }}
                </b-badge>
              </div>
            </td> -->

              <td>
                <div v-if="row.shipping_diff > 0 || row.shipping_diff < 0">
                  <b-badge variant="light-danger">
                    {{ row.shipping_paid | number }} /
                    {{ row.shipping_price | number }}
                  </b-badge>
                </div>
                <div v-else>
                  {{ row.shipping_paid | number }}
                </div>
              </td>
              <td>
                {{ row.discount_price | number }}
              </td>
              <td>
                {{ row.withholding_tax_price | number }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-overlay>

    <div v-if="debug">
      sumPaidRental: {{ sumPaidRental }} <br />
      sumPaidShipping: {{ sumPaidShipping }} <br />
      sumPaidDeposit:{{ sumPaidDeposit }} <br />
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BButton,
  BBadge,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
import DatePicker from "./component/Datepicker.vue";
import DatePickerRange from "./component/DatepickerRange.vue";
import AccountTable from "./component/AccountTable.vue";
// import AccountSummary from "./component/AccountSummary.vue";
import AccountSeperate from "./component/AccountSeperate.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import AccountChecklist from "./component/AccountChecklist.vue";

import moment from "moment";
import { BTable } from "bootstrap-vue";
import "moment-timezone";
import _ from "lodash";

export default {
  components: {
    BCardBody,
    BTable,
    AccountTable,
    DatePicker,
    DatePickerRange,
    BCard,
    BCardText,
    BButton,
    BRow,
    BCol,
    // AccountSummary,
    BBadge,
    BFormCheckbox,
    AccountSeperate,
    AppCollapse,
    AppCollapseItem,
    AccountChecklist,
    BOverlay,
  },
  data() {
    return {
      loading: false,
      form: {
        date: {
          start: moment().format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
        },
        show_table: true,
        show_diff: false,
      },
      table_data: [],
      items: [],
      month_info: {},
      total_month: [],
      debug: false,
    };
  },
  computed: {
    filterTableData() {
      if (this.form.show_diff) {
        return this.table_data.filter(
          (item) =>
            item.rental_diff !== 0 ||
            item.deposit_diff !== 0 ||
            item.shipping_diff !== 0
        );
      } else {
        return this.table_data;
      }
    },

    sumPaidRental() {
      return this.filterTableData.reduce(
        (sum, row) => sum + row.rental_paid,
        0
      );
    },
    sumPaidShipping() {
      return this.filterTableData.reduce(
        (sum, row) => sum + row.shipping_paid,
        0
      );
    },
    sumPaidDeposit() {
      return this.filterTableData.reduce(
        (sum, row) => sum + row.deposit_paid,
        0
      );
    },
    sumOrderRental() {
      return this.filterTableData.reduce(
        (sum, row) => sum + row.rental_price,
        0
      );
    },
    sumOrderDiscount() {
      return this.filterTableData.reduce(
        (sum, row) => sum + row.discount_price,
        0
      );
    },
    sumOrderWithHolding() {
      return this.filterTableData.reduce(
        (sum, row) => sum + row.withholding_tax_price,
        0
      );
    },
  },
  created() {
    this.load_data();
  },
  methods: {
    open_new(id) {
      window.open("/order/view/" + id, "_blank");
    },
    day: function (date) {
      return moment(date).format("DD/MM");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
    show_diff(order, payment) {
      if (order - payment == 0) {
        return false;
      } else {
        return true;
      }
    },
    get_price(obj, text) {
      for (const x of obj) {
        if (x.payment_type == text) {
          return x.totalAmount;
        }
      }
      return;
    },
    date_picked(x) {
      this.form.date = x;
      this.load_data();
    },
    async load_data() {
      this.loading = true;
      // this.form.date = moment().subtract(1, "days").format('YYYY-MM-DD') /// override วันที่ชั่วคราว
      await this.$http({
        method: "POST",
        url: `/account/today`,
        data: this.form,
      }).then((x) => {
        this.loading = false;
        const data = x.data.data;
        this.table_data = data.table_data;
        this.total_month = data.total_month;
      });
      let total_form_req = {};
      total_form_req = this.form;
      total_form_req.range = "month";
    },
  },
  filters: {
    day: function (date) {
      return moment(date).format("DD/MM");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
    number: function (x) {
      if (x != null) {
        let rounded = Number(x).toFixed(2);

        // Remove the decimal part if it's .00
        if (rounded.endsWith(".00")) {
          rounded = rounded.substring(0, rounded.length - 3);
        }
        return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>
<style>
.align-vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
}
.table-responsive {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
</style>
