<template>
  <div>
    <b-modal
      id="modal-view-product-sub"
      ref="modal-view-product-sub"
      title="รายละเอียดอุปกรณ์ย่อย"
      no-close-on-backdrop
      size="lg"
      hide-footer
    >
      <div class="d-flex justify-content-between">
        <div>วันที่ซื้อ</div>
        <div>
          {{ form.purchase_date | date }}
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div>ประกันหมด</div>
        <div>
          {{ form.warranty_end | date }}
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div>ประมาณวันที่ขายออก</div>
        <div>
          {{ form.estimate_sale_date | date }}
        </div>
      </div>

      <note-log-list
        :update_id="form._id"
        location="subproduct"
        title="Note อุปกรณ์"
      >
      </note-log-list>
    </b-modal>
  </div>
</template>
<script>
import {
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
  BButton,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import NoteLogList from "../../components/NoteLogList.vue";
import moment from "moment";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormRadioGroup,
    BFormRadio,
    DatePicker,
    NoteLogList,
  },
  created() {},
  data() {
    return {};
  },
  props: ["form"],
  methods: {},
  filters: {
    date: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>
<style>
.bg-white {
  background-color: #283046 !important;
}
</style>
