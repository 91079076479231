<template>
  <div>
    <b-modal
      id="modal-add-product-sub"
      ref="modal-add-product-sub"
      title="เพิ่มอุปกรณ์ย่อย"
      no-close-on-backdrop
      size="lg"
      hide-footer
    >
      <validation-observer ref="addProductSub">
        <b-form @submit.prevent="addProductSub">
          <!-- Serial -->
          <b-form-group label="Serial Number">
            <validation-provider
              #default="{ errors }"
              name="Serial"
              rules="required"
              autocomplete="off"
            >
              <b-form-input
                v-model="form.serial"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ตัวที่ -->
          <b-form-group label="No.">
            <validation-provider
              #default="{ errors }"
              name="No"
              rules="required"
              autocomplete="off"
            >
              <b-form-input
                v-model="form.number"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- วันที่ซื้อ -->
          <b-form-group label="วันที่ซื้อ">
            <validation-provider
              #default="{ errors }"
              name="PurchaseDate"
              rules="required"
            >
              <date-picker
                v-model="form.purchase_date"
                :masks="{ input: 'D/M/YYYY' }"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <b-form-group>
                    <!-- :mode="date-time" -->
                    <b-form-input
                      class="bg-white border px-2 py-1 rounded"
                      :value="inputValue"
                      @click="togglePopover()"
                      autocomplete="off"
                    />
                  </b-form-group>
                </template>
              </date-picker>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- วันที่หมดประกัน -->
          <b-form-group label="วันที่หมดประกัน" class="mb-1">
            <validation-provider
              #default="{ errors }"
              name="warranty_end"
              rules="required"
              autocomplete="off"
            >
              <date-picker
                v-model="form.warranty_end"
                :masks="{ input: 'D/M/YYYY' }"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <b-form-group>
                    <!-- :mode="date-time" -->
                    <b-form-input
                      class="bg-white border px-2 py-1 rounded"
                      :value="inputValue"
                      @click="togglePopover()"
                      autocomplete="off"
                    />
                  </b-form-group>
                </template>
              </date-picker>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <b-button size="sm" class="mr-1" @click="setWarranty(1)"
              >1 ปี</b-button
            >
            <b-button size="sm" class="mr-1" @click="setWarranty(2)"
              >2 ปี</b-button
            >
            <b-button size="sm" class="mr-1" @click="setWarranty(3)"
              >3 ปี</b-button
            >
          </b-form-group>

          <!-- วันที่ขายออก -->
          <b-form-group label="ประมาณวันที่ขายออก">
            <validation-provider
              #default="{ errors }"
              name="sell date"
              rules="required"
            >
              <date-picker
                v-model="form.estimate_sale_date"
                :masks="{ input: 'D/M/YYYY' }"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <b-form-group>
                    <!-- :mode="date-time" -->
                    <b-form-input
                      class="bg-white border px-2 py-1 rounded"
                      :value="inputValue"
                      @click="togglePopover()"
                      autocomplete="off"
                    />
                  </b-form-group>
                </template>
              </date-picker>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <b-button size="sm" class="mr-1" @click="setSale(1)">1 ปี</b-button>
            <b-button size="sm" class="mr-1" @click="setSale(2)">2 ปี</b-button>
            <b-button size="sm" class="mr-1" @click="setSale(3)">3 ปี</b-button>
            <b-button size="sm" class="mr-1" @click="setSale(4)">4 ปี</b-button>
            <b-button size="sm" class="mr-1" @click="setSale(5)">5 ปี</b-button>
          </b-form-group>

          <!-- ราคาซื้อ -->
          <b-form-group label="ราคาซื้อ">
            <validation-provider
              #default="{ errors }"
              name="Purchase_Price"
              rules="required"
              autocomplete="off"
            >
              <b-form-input
                v-model="form.purchase_price"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ราคาขายออก -->
          <b-form-group label="ราคาขายออก">
            <validation-provider
              #default="{ errors }"
              name="estimate_sale_price"
              rules="required"
              autocomplete="off"
            >
              <b-form-input
                v-model="form.estimate_sale_price"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- จัดชุด -->
          <b-form-group>
            <h5>จัดชุด</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.is_kit"
                name="some-radio5"
                :value="false"
              >
                ไม่
              </b-form-radio>
              <b-form-radio
                v-model="form.is_kit"
                name="some-radio5"
                :value="true"
              >
                จัดชุด
              </b-form-radio>
            </div>
          </b-form-group>

          <!-- ของใหม่ / มือ 2 -->
          <b-form-group>
            <h5>ของใหม่ / มือสอง</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.purchase_condition"
                name="some-radio"
                value="new"
                autocomplete="off"
              >
                New
              </b-form-radio>
              <b-form-radio
                v-model="form.purchase_condition"
                name="some-radio"
                value="used"
              >
                Used
              </b-form-radio>
            </div>
          </b-form-group>
          <!-- สถานะ -->
          <b-form-group>
            <h5>สถานะการใช้เช่า</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.product_status"
                name="some-radios2"
                value="ready"
              >
                พร้อมให้เช่า
              </b-form-radio>
              <b-form-radio
                v-model="form.product_status"
                name="some-radios2"
                value="repair"
              >
                ส่งซ่อม
              </b-form-radio>
              <b-form-radio
                v-model="form.product_status"
                name="some-radios2"
                value="lost"
              >
                หาย
              </b-form-radio>
              <b-form-radio
                v-model="form.product_status"
                name="some-radios2"
                value="disable"
              >
                งดให้เช่า
              </b-form-radio>
              <b-form-radio
                v-model="form.product_status"
                name="some-radios2"
                value="sold"
              >
                ขายแล้ว
              </b-form-radio>
            </div>
          </b-form-group>
          <hr />
          <div class="text-right justify">
            <b-button variant="relief-secondary" class="mr-1" @click="cancel">
              Cancel
            </b-button>
            <b-button
              v-if="!loading"
              variant="relief-success"
              @click="validationForm"
            >
              เพิ่มอุปกรณ์
            </b-button>
            <b-button
              variant="relief-success"
              disabled
              class="mr-1"
              v-if="loading"
            >
              <b-spinner small />
              Loading...
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
  BButton,
  BFormRadio,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import * as axios from "axios";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

// Vue.component('date-picker', DatePicker)

export default {
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormRadio,
    DatePicker,
  },
  data() {
    return {
      form: {
        purchase_condition: "new",
        product_status: "ready",
        is_kit: false,
        purchase_date: null,
        warranty_end: null,
        estimate_sale_date: null,
      },
      required,
      loading: false,
    };
  },
  created() {},
  methods: {
    validationForm() {
      this.$refs.addProductSub.validate().then((success) => {
        if (success) {
          this.$http({
            method: "POST",
            url: `/product-sub/` + this.$route.params.id,
            data: this.form,
          }).then(async (x) => {
            this.$refs["modal-add-product-sub"].hide();
            this.form = {
              purchase_condition: "new",
              product_status: "ready",
            };
            await this.$root.$emit("edit-product-complete");
          });
        }
      });
    },
    cancel() {
      this.$refs["modal-add-product-sub"].hide();
    },
    setWarranty(years) {
      const purchaseDate = new Date(this.form.purchase_date);
      const newWarrantyDate = new Date(
        purchaseDate.getFullYear() + years,
        purchaseDate.getMonth(),
        purchaseDate.getDate()
      );
      newWarrantyDate.setDate(newWarrantyDate.getDate() - 1);
      this.form.warranty_end = newWarrantyDate;
    },
    setSale(years) {
      const purchaseDate = new Date(this.form.purchase_date);
      const newsaleDate = new Date(
        purchaseDate.getFullYear() + years,
        purchaseDate.getMonth(),
        purchaseDate.getDate()
      );
      this.form.estimate_sale_date = newsaleDate;
    },
  },
};
</script>

<style>
.bg-white {
  background-color: #283046 !important;
}
</style>
