<template>
  <div>
    <div class="row mb-1">
      <div class="col">
        <div class="row mb-1">
          <div class="col d-flex align-items-center flex-wrap">
            <div v-if="member.promptpay_checked !== true">
              <b-badge variant="danger" class="mr-1">
                ยังไม่ได้เช็ค Prompt Pay
              </b-badge>
            </div>
            <div v-else>
              <div v-if="member.screening">
                <div v-if="member.screening.pp_detect === true">
                  <b-badge variant="success" class="mr-1">มี PromptPay</b-badge>
                  <div v-if="member.screening.pp_same_name === false">
                    <b-badge variant="danger" class="mr-1">
                      ชื่อไม่ตรง
                    </b-badge>
                    <span>{{ member.screening.pp_name_found }}</span>
                  </div>
                  <div v-if="member.screening.pp_phone === false">
                    <b-badge variant="danger" class="mr-1">
                      เบอร์ไม่มี PromptPay
                    </b-badge>
                  </div>
                </div>
                <div v-else>
                  <b-badge variant="danger" class="mr-1"
                    >ไม่มี PromptPay</b-badge
                  >
                </div>
              </div>
            </div>
            <b-badge variant="success" class="mr-1" v-if="member.id_card_id">
              อ่านบัตรประชาชนแล้ว
            </b-badge>
            <b-badge variant="danger" class="mr-1" v-else>
              ยังไม่ได้อ่านบัตร
            </b-badge>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <b-button
          size="md"
          variant="outline-primary"
          class="btn-tour-skip mr-1 mb-1"
          @click="systemBlacklist(member)"
          :disabled="blacklist_loading"
        >
          <b-spinner small v-if="blacklist_loading" /> System BL
        </b-button>

        <b-button
          size="md"
          variant="outline-primary"
          class="btn-tour-skip mr-1 mb-1"
          @click="search_en_name_FB(member)"
        >
          <feather-icon icon="FacebookIcon" size="12" />
          EN Name
        </b-button>

        <b-button
          size="md"
          variant="outline-primary"
          class="btn-tour-skip mr-1 mb-1"
          @click="search_name_FB(member)"
        >
          <feather-icon icon="FacebookIcon" size="12" />
          Name
        </b-button>

        <b-button
          size="md"
          variant="outline-primary"
          class="btn-tour-skip mr-1 mb-1"
          @click="searchGG(member)"
        >
          (G) NAME
        </b-button>
        <b-button
          size="md"
          variant="outline-primary"
          class="btn-tour-skip mr-1 mb-1"
          @click="searchGGPhone(member)"
        >
          <feather-icon icon="PhoneIcon" size="12" />
          (G) Phone
        </b-button>
        <b-button
          size="md"
          variant="outline-primary"
          class="btn-tour-skip mr-1 mb-1"
          @click="searchGGBL(member)"
        >
          <feather-icon icon="AlertTriangleIcon" size="14" />
          BL (G)
        </b-button>
        <b-button
          size="md"
          variant="outline-primary"
          class="btn-tour-skip mr-1 mb-1"
          @click="search_name_BL(member)"
          :disabled="loading.name_BL"
        >
          <feather-icon icon="AlertTriangleIcon" size="14" />
          BL NAME <b-spinner small v-if="loading.name_BL" />
        </b-button>
        <b-button
          size="md"
          variant="outline-primary"
          class="btn-tour-skip mr-1 mb-1"
          @click="search_id_BL(member)"
          :disabled="loading.id_BL"
        >
          <feather-icon icon="AlertTriangleIcon" size="14" />
          BL ID <b-spinner small v-if="loading.id_BL" />
        </b-button>
        <b-button
          size="md"
          variant="outline-primary"
          class="btn-tour-skip mr-1 mb-1"
          @click="search_lastname_BL(member)"
          :disabled="loading.lastname_BL"
        >
          <feather-icon icon="AlertTriangleIcon" size="14" />
          BL LAST NAME <b-spinner small v-if="loading.lastname_BL" />
        </b-button>
        <b-button
          size="md"
          variant="outline-primary"
          class="btn-tour-skip mr-1 mb-1"
          @click="search_phone_BL(member)"
          :disabled="loading.phone_BL"
        >
          <feather-icon icon="AlertTriangleIcon" size="14" />
          BL Phone <b-spinner small v-if="loading.phone_BL" />
        </b-button>
        <b-button
          size="md"
          variant="outline-primary"
          class="btn-tour-skip mr-1 mb-1"
          @click="search_name_X(member)"
        >
          <feather-icon icon="TwitterIcon" size="12" />
          X Name
        </b-button>
        <b-button
          size="md"
          variant="outline-primary"
          class="btn-tour-skip mr-1 mb-1"
          @click="search_email_GG(member)"
        >
          <feather-icon icon="MailIcon" size="12" />
          (G) Email
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BSpinner, BBadge } from "bootstrap-vue";
export default {
  components: {
    BButton,
    BSpinner,
    BBadge,
  },
  data() {
    return {
      blacklist_loading: false,
      loading: {
        name_BL: false,
        id_BL: false,
        lastname_BL: false,
        phone_BL: false,
      },
    };
  },
  created() {},
  props: ["member"],
  methods: {
    searchGG(member) {
      const query = `"${member.first_name} ${member.last_name}"`;
      const url = `https://www.google.co.th/search?q=${encodeURIComponent(
        query
      )}`;
      window.open(url, "_blank");
    },
    searchGGPhone(member) {
      const phone = this.formatPhoneNumber(member.phone);

      const format1 = phone; // 0819610757
      const format2 = phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2$3"); // 081-9610757
      const format3 = phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"); // 081-961-0757

      // Create the search query string
      const query = `"${format1}" OR "${format2}" OR "${format3}"`;
      const url = `https://www.google.com/search?q=${encodeURIComponent(
        query
      )}`;
      window.open(url, "_blank");
    },
    formatPhoneNumber(phoneNumber) {
      // Remove any hyphens or spaces
      let cleaned = phoneNumber.replace(/[-\s]/g, "");
      // Replace +66 with 0 at the beginning
      if (cleaned.startsWith("+66")) {
        cleaned = cleaned.replace("+66", "0");
      }
      // Ensure the phone number starts with 0
      if (!cleaned.startsWith("0")) {
        cleaned = "0" + cleaned.slice(1);
      }
      // Return the first 10 digits to ensure the correct length
      return cleaned.slice(0, 10);
    },
    searchGGBL(member) {
      const query = `"${member.first_name} ${member.last_name}"`;
      const url = `https://www.google.com/search?q=${encodeURIComponent(
        query
      )}+site:${encodeURIComponent("blacklistseller.com")}`;
      window.open(url, "_blank");
    },
    search_email_GG(member) {
      const query = `"${member.email}"`;
      const url = `https://www.google.com/search?q=${encodeURIComponent(
        query
      )}`;
      window.open(url, "_blank");
    },
    async search_name_BL(member) {
      this.loading.name_BL = true;
      const fields = {
        first_name: `${member.first_name}`,
        last_name: `${member.last_name}`,
      };
      await this.$http({
        method: "POST",
        url: `/member/thirdparty/bl`,
        data: fields,
      }).then((x) => {
        this.loading.name_BL = false;
        if (x.data.success) {
          const htmlContent = x.data.html;
          const newWindow = window.open("", "_blank");
          newWindow.document.open();
          newWindow.document.write(htmlContent);
          newWindow.document.close();
        } else {
          this.toast_error("name_BL Error", x.data.message);
        }
      });
    },
    async search_lastname_BL(member) {
      this.loading.lastname_BL = true;
      const fields = {
        last_name: `${member.last_name}`,
      };
      await this.$http({
        method: "POST",
        url: `/member/thirdparty/bl`,
        data: fields,
      }).then((x) => {
        this.loading.lastname_BL = false;
        if (x.data.success) {
          const htmlContent = x.data.html;
          const newWindow = window.open("", "_blank");
          newWindow.document.open();
          newWindow.document.write(htmlContent);
          newWindow.document.close();
        } else {
          this.toast_error("lastname_BL Error", x.data.message);
        }
      });
    },
    async search_id_BL(member) {
      this.loading.id_BL = true;
      const fields = {
        idcard: `${member.card_id}`, // Add the ID card value if needed
      };
      await this.$http({
        method: "POST",
        url: `/member/thirdparty/bl`,
        data: fields,
      }).then((x) => {
        this.loading.id_BL = false;
        if (x.data.success) {
          const htmlContent = x.data.html;
          const newWindow = window.open("", "_blank");
          newWindow.document.open();
          newWindow.document.write(htmlContent);
          newWindow.document.close();
        } else {
          this.toast_error("id_BL Error", x.data.message);
        }
      });
    },
    async search_phone_BL(member) {
      this.loading.phone_BL = true;
      const fields = {
        bank_number: `${member.phone}`, // Add the ID card value if needed
      };
      await this.$http({
        method: "POST",
        url: `/member/thirdparty/bl`,
        data: fields,
      }).then((x) => {
        this.loading.phone_BL = false;
        if (x.data.success) {
          const htmlContent = x.data.html;
          const newWindow = window.open("", "_blank");
          newWindow.document.open();
          newWindow.document.write(htmlContent);
          newWindow.document.close();
        } else {
          this.toast_error("phone_BL Error", x.data.message);
        }
      });
    },
    search_name_X(member) {
      const query = `${member.first_name} ${member.last_name}`;
      const url = `https://x.com/search?q=${encodeURIComponent(query)}`;
      window.open(url, "_blank");
    },
    search_name_FB(member) {
      const query = `${member.first_name} ${member.last_name}`;
      const url = `https://www.facebook.com/search/top/?q=${encodeURIComponent(
        query
      )}`;
      window.open(url, "_blank");
    },
    search_en_name_FB(member) {
      const query = `${member.en_first_name} ${member.en_last_name}`;
      const url = `https://www.facebook.com/search/top/?q=${encodeURIComponent(
        query
      )}`;
      window.open(url, "_blank");
    },
    run_check_BL(fields) {
      const form = document.createElement("form");
      form.action =
        "https://www.blacklistseller.com/report/report_search_success_page";
      form.method = "POST";
      form.target = "_blank"; // Open in a new tab

      for (const key in fields) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = fields[key];
        form.appendChild(input);
      }
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form); // Clean up
    },
    systemBlacklist(member) {
      this.blacklist_loading = true;
      this.$http({
        method: "POST",
        url: `/member/blacklist/check`,
        data: member,
      })
        .then((x) => {
          this.blacklist_loading = false;
          console.log("xxx", x.data.data);
          this.$root.$emit("edit-member-complete");
          const res = x.data.data;
          if (res.isBlacklisted === false) {
            this.toast_success("ปกติ", "ไม่มีในข้อมูล Blaclist", "success");
          }
        })
        .catch((e) => {
          console.log("error", e.message);
        });
    },
  },
};
</script>
