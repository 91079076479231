<template>
  <div>
    <b-card no-body class="p-2">
      <div class="row">
        <div class="col"><h4>ที่อยู่ออกใบกำกับภาษี</h4></div>
        <div class="col text-right">
          <b-button
            variant="primary"
            v-b-modal.modal-add-billing-address
            size="sm"
            class="mr-1"
          >
            เพิ่มที่อยู่ออกใบกำกับภาษี
          </b-button>
        </div>
      </div>

      <billing-list :data="billing_list"> </billing-list>
    </b-card>
    <add-billing-modal
      :member_id="member_id"
      @finished="Loaddata"
    ></add-billing-modal>
  </div>
</template>

<script>
import { BCard, BButton } from "bootstrap-vue";
import AddBillingModal from "./AddBillingModal.vue";
import BillingList from "./Billinglist.vue";

export default {
  components: {
    BCard,
    BButton,
    AddBillingModal,
    BillingList,
  },
  props: ["member_id"],
  data() {
    return {
      billing_list: [],
    };
  },
  created() {
    this.Loaddata();
  },
  methods: {
    Loaddata() {
      if (this.member_id) {
        this.$http({
          method: "POST",
          url: `/member/billing-address/list`,
          data: { member_id: this.member_id },
        }).then((x) => {
          this.billing_list = x.data.data;
        });
      }
    },
  },
};
</script>
