<template>
  <div>
    <h1>รายรับแยกบัญชี</h1>
    <!-- ขอบเขตของรายรับคือ แสดงเฉพาะรายรับที่ได้รับ
    เป็นเงินที่ได้รับในวันนี้เช่นโอนล่วงหน้า / จ่ายค่าเช่าวันนี้ -->
    <b-card>
      <div class="row">
        <div class="col-12 col-md-8 text-left align-vertical-center">
          <date-account-selector @selected="account_picked">
          </date-account-selector>
        </div>
        <!-- v-if="ROLE === 'owner'" -->
        <div class="col-12 col-md-4 text-right">
          <b-form-group label="วันที่">
            <date-picker-range @date_picked="date_picked"></date-picker-range>
          </b-form-group>
        </div>
      </div>
      <hr v-if="ROLE === 'owner'" />
      <div class="row">
        <div class="col-md-4 col-12">
          <div v-if="ROLE === 'owner'">
            <h4>ยอดสะสม</h4>
            <div
              class="d-flex justify-content-between"
              v-for="(value, key) in income_summary_whole"
              :key="key"
            >
              <div>{{ value.payment_type | p_type }}</div>
              <div>{{ value.sum | number }}</div>
            </div>
            <div class="d-flex justify-content-between">
              <div>ยังไม่ได้ตัดยอด:</div>
              <div>{{ unassign_balance | number }}</div>
            </div>
            <div class="d-flex justify-content-between">
              <h4>เงินคงเหลือในบัญชี:</h4>
              <h4>{{ avaliable_balance | number }}</h4>
            </div>
          </div>

          <div v-else>
            <div
              class="d-flex justify-content-between"
              v-for="(value, key) in income_summary_whole_staff"
              :key="key"
            >
              <div>{{ value.payment_type | p_type }}</div>
              <div>{{ value.sum | number }}</div>
            </div>

            <div class="d-flex justify-content-between">
              <div>ยังไม่ได้ตัดยอด:</div>
              <div>{{ unassign_balance | number }}</div>
            </div>
            <div class="d-flex justify-content-between">
              <h4>เงินคงเหลือในบัญชี:</h4>
              <h4>{{ avaliable_balance | number }}</h4>
            </div>
          </div>
        </div>
        <div class="col-6"></div>
      </div>
    </b-card>

    <!-- tableDataWithCumsum -->

    <b-card class="pt-0">
      <div class="demo-inline-spacing">
        <b-form-radio
          v-model="tab_select"
          name="some-radios9"
          value="book_transaction"
          class="mt-0"
        >
          ยอดสมุดบัญชี
        </b-form-radio>
        <b-form-radio
          v-model="tab_select"
          name="some-radios9"
          value="internal_transaction"
          class="mt-0"
        >
          ยอดภายใน
        </b-form-radio>
        <b-form-radio
          v-model="tab_select"
          name="some-radios9"
          value="deposit_list"
          class="mt-0"
        >
          เงินประกันในระบบ
        </b-form-radio>

        <b-form-radio
          v-model="tab_select"
          name="some-radios9"
          value="damage_list"
          class="mt-0"
        >
          อุปกรณ์เสียหาย
        </b-form-radio>

        <b-form-radio
          v-model="tab_select"
          name="some-radios9"
          value="sale_list"
          class="mt-0"
        >
          ขายอุปกรณ์
        </b-form-radio>

        <b-form-radio
          v-model="tab_select"
          name="some-radios9"
          value="unassign_list"
          class="mt-0"
        >
          ยังไม่ได้ตัดยอด
        </b-form-radio>
      </div>

      <div v-if="tab_select == 'internal_transaction'">
        <hr class="mb-0" />
        <div class="demo-inline-spacing">
          <b-form-checkbox v-model="checkbox.rental"> ค่าเช่า </b-form-checkbox>
          <b-form-checkbox v-model="checkbox.advance_rental">
            ค่าเช่าล่วงหน้า
          </b-form-checkbox>
          <b-form-checkbox v-model="checkbox.deposit">
            เงินประกัน
          </b-form-checkbox>
          <b-form-checkbox v-model="checkbox.advance_deposit">
            เงินประกันล่วงหน้า
          </b-form-checkbox>
          <b-form-checkbox v-model="checkbox.shipping">
            ค่าส่ง
          </b-form-checkbox>
          <b-form-checkbox v-model="checkbox.damage">
            อุปกรณ์เสียหาย
          </b-form-checkbox>
          <b-form-checkbox v-model="checkbox.sale"> ขายของ </b-form-checkbox>
          <b-form-checkbox v-model="system_only"> SYSTEM ONLY </b-form-checkbox>
        </div>
      </div>
      <b-row class="py-1">
        <b-col> </b-col>
      </b-row>
      <div class="row">
        <div class="col text-right">
          <b-button
            variant="relief-success"
            class="btn-icon"
            @click="loadAccount()"
          >
            <b-spinner small v-if="is_loading" /> Search</b-button
          >
        </div>
      </div>
    </b-card>

    <!-- รายการเดินบัญชี -->
    <div v-if="tab_select === 'book_transaction'">
      <div class="d-flex justify-content-between">
        <h4>รายการเดินบัญชี</h4>
        <div class="mb-1">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-add-transaction
            variant="relief-success"
            size="sm"
          >
            เงินเข้า / เงินออก
          </b-button>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th v-for="x in transaction_fields" :key="x.id">{{ x.label }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="tableDataWithCumsum.length == 0">
              <td colspan="6">ไม่มีรายการ</td>
            </tr>
            <tr v-if="tableDataWithCumsum.length != 0">
              <td></td>
              <td></td>
              <td class="text-right">รวม</td>
              <td>{{ transaction_info.sum_deposit | number }}</td>
              <td>{{ transaction_info.sum_withdraw | number }}</td>
              <td>
                {{
                  (transaction_info.sum_deposit - transaction_info.sum_withdraw)
                    | number
                }}
              </td>
            </tr>
            <tr
              v-for="(row, index) in tableDataWithCumsum"
              :key="index"
              v-if="tableDataWithCumsum.length != 0"
            >
              <td>
                {{ row.date | day }}
                <b-badge variant="light-info">
                  {{ row.date | time }}
                </b-badge>
              </td>
              <td>
                <div v-if="row.order">
                  <div>
                    <b-button
                      variant="relief-success"
                      class="btn-icon"
                      @click="open_new(row.order._id)"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </div>
                  <div>
                    <small>{{ row.order.order_id }}</small>
                  </div>
                </div>
                <div v-else>SYSTEM</div>
              </td>
              <td class="text-nowrap">
                <div v-if="row.member">
                  <div>
                    {{ row.member.first_name }}
                    {{ row.member.last_name }}
                    <span v-if="row.member.retro_username">
                      ( {{ row.member.retro_username }} )
                    </span>
                    <span v-else-if="row.member.gen_username">
                      ( {{ row.member.gen_username }} )
                    </span>
                  </div>
                </div>
                <div v-else>
                  <span v-if="row.description">{{ row.description }} (d)</span>
                  <span v-if="row.note">{{ row.note }} (n)</span>
                </div>
              </td>
              <td>
                <div v-if="row.side === 'deposit'">
                  {{ row.amount | number }}
                </div>
              </td>
              <td>
                <div v-if="row.side === 'withdraw'">
                  {{ row.amount | number }}
                </div>
              </td>
              <td>
                <confirm-delete
                  v-if="ROLE === 'owner'"
                  title="ลบ"
                  :data="row._id"
                  @delete="deleteTransaction"
                />
              </td>
            </tr>
            <tr v-if="tableDataWithCumsum.length != 0">
              <td></td>
              <td></td>
              <td class="text-right">รวม</td>
              <td>{{ transaction_info.sum_deposit | number }}</td>
              <td>{{ transaction_info.sum_withdraw | number }}</td>
              <td>
                {{
                  (transaction_info.sum_deposit - transaction_info.sum_withdraw)
                    | number
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- รายการย่อย ภายใน -->
    <div v-if="tab_select === 'internal_transaction'">
      <h4>ยอดภายใน</h4>
      <b-card no-body>
        <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr>
                <td>Time</td>
                <td>Order</td>
                <td>Member</td>
                <td>ประเภทเงิน</td>
                <td>เข้า</td>
                <td>ออก</td>
                <td>note</td>
                <td v-if="ROLE === 'owner'">ลบ</td>
              </tr>
              <tr v-if="income_table.length == 0">
                <td colspan="7">ไม่มีรายการ</td>
              </tr>
              <tr v-if="income_table.length != 0">
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">รวม</td>
                <td>
                  {{ income_info.sum_deposit | number }}
                </td>
                <td>{{ income_info.sum_withdraw | number }}</td>
                <td>
                  {{
                    (income_info.sum_deposit - income_info.sum_withdraw)
                      | number
                  }}
                </td>
                <td></td>
              </tr>
              <tr v-for="(y, index) in income_table" :key="index">
                <td>
                  {{ y.date | day }}
                  <b-badge variant="light-info">
                    {{ y.date | time }}
                  </b-badge>
                </td>
                <td>
                  <div v-if="y.order">
                    <div>
                      <b-button
                        variant="relief-success"
                        class="btn-icon"
                        @click="open_new(y.order_id)"
                      >
                        <feather-icon icon="SearchIcon" />
                      </b-button>
                      <!-- <br /> -->
                      <small><br />{{ y.order.order_id }}</small>
                    </div>
                  </div>
                  <div v-else>SYSTEM</div>
                  <div></div>
                </td>
                <td>
                  <div v-if="y.member">
                    <div v-if="y.member.retro_username">
                      ( {{ y.member.retro_username }} )
                      {{ y.member.first_name }} {{ y.member.last_name }}
                    </div>
                    <div v-else-if="y.member.gen_username">
                      {{ y.member.gen_username }}
                      {{ y.member.first_name }} {{ y.member.last_name }}
                    </div>
                  </div>
                </td>
                <td>
                  <b-badge
                    pill
                    variant="light-success"
                    v-if="y.payment_type === 'rental'"
                  >
                    {{ y.payment_type | p_type }}
                  </b-badge>

                  <span v-else>
                    {{ y.payment_type | p_type }}
                  </span>
                </td>
                <td>
                  <div v-if="y.side == 'deposit'">
                    {{ y.amount | number }}
                  </div>
                </td>
                <td>
                  <div v-if="y.side == 'withdraw'">
                    {{ y.amount | number }}
                  </div>
                </td>
                <td>
                  <span v-if="y.description">{{ y.description }} (d)</span>
                  <span v-if="y.note">n:{{ y.note }} (n)</span>
                </td>
                <td v-if="ROLE === 'owner'">
                  <confirm-delete
                    title="ลบ"
                    :data="y._id"
                    @delete="deleteTransaction"
                  />
                </td>
              </tr>
              <tr v-if="income_table.length != 0">
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">รวม</td>
                <td>
                  {{ income_info.sum_deposit | number }}
                </td>
                <td>{{ income_info.sum_withdraw | number }}</td>
                <td>
                  {{
                    (income_info.sum_deposit - income_info.sum_withdraw)
                      | number
                  }}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <hr />
        <b-row class="pl-3 pr-3" v-if="ROLE === 'owner'">
          <b-col v-for="(value, key) in income_summary" :key="key">
            <h5>{{ key | p_type }}</h5>
            <p>เข้า: {{ value.deposit | number }}</p>
            <p>ออก: {{ value.withdraw | number }}</p>
            <p>คงเหลือ: {{ (value.deposit - value.withdraw) | number }}</p>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <!-- เงินประกันในระบบ -->
    <div v-if="tab_select === 'deposit_list'">
      <b-card>
        <deposit-list-table
          class="table table-responsive"
          :data="deposit_list_table"
        ></deposit-list-table>
      </b-card>
    </div>

    <div v-if="tab_select === 'unassign_list'">
      <b-card>
        <!-- <h4>ยังไม่ได้ตัดยอด</h4> -->
        <unassign-list-table
          class="table table-responsive"
          :data="unassign_list_table"
        ></unassign-list-table>
      </b-card>
    </div>

    <!-- อุปกรณ์เสียหาย -->
    <div v-if="tab_select === 'damage_list'">
      <b-card>
        <damage-list-table
          :data="damage_table"
          class="table table-responsive"
        />
      </b-card>
    </div>

    <!-- ขายอุปกรณ์ -->
    <div v-if="tab_select === 'sale_list'">
      <b-card>
        <damage-list-table
          :data="damage_table"
          class="table table-responsive"
        />
      </b-card>
    </div>

    <add-transaction-modal :account_id="this.form.account_id" />
  </div>
</template>

<script>
import {
  BLink,
  BCard,
  BRow,
  BCol,
  BTable,
  BButton,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BFormCheckbox,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
import DatePickerRange from "./component/DatepickerRange.vue";
import BankAccountInfoTable from "./component/BankAccountInfoTable.vue";
import DepositListTable from "./component/DepositListTable.vue";
import DamageListTable from "./component/DamageListTable.vue";
import UnassignListTable from "./component/UnassignListTable.vue";
import BankAccountSingle from "./component/BankAccountSingle.vue";
import DateAccountSelector from "./component/DateAccountSelector.vue";
import AddTransactionModal from "./component/AddTransactionModal.vue";
import ConfirmDelete from "../components/ConfirmDeleteButton.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { getUserRole } from "@/auth/utils";

import moment from "moment";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BLink,
    BCard,
    BRow,
    BCol,
    BTable,
    BButton,
    BFormCheckbox,
    BankAccountInfoTable,
    BankAccountSingle,
    BFormGroup,
    BFormInput,
    DateAccountSelector,
    DepositListTable,
    DamageListTable,
    AddTransactionModal,
    ConfirmDelete,
    DatePickerRange,
    BFormRadio,
    BFormRadioGroup,
    UnassignListTable,
    BBadge,
    BSpinner,
  },
  computed: {
    income_summary_whole_staff() {
      if (this.income_summary_whole) {
        const filteredSummary = this.income_summary_whole.filter(
          (item) =>
            item.payment_type === "advance_deposit" ||
            item.payment_type === "deposit" ||
            item.payment_type === "advance_rental"
        );
        return filteredSummary;
      } else {
        return [];
      }
    },
    checkedBoxes() {
      return Object.keys(this.checkbox).filter((key) => this.checkbox[key]);
    },
    tableDataWithCumsum() {
      /// รายการเดินบัญชี
      let cumsum = this.info.avaliable_begin;
      return this.transaction_table.map((item) => {
        if (item.side === "deposit") {
          cumsum += item.amount;
        } else if (item.side === "withdraw") {
          cumsum -= item.amount;
        }
        return { ...item, cumsum }; // Add cumsum to each item
      });
    },

    // incomeTableFilter() {
    //   return this.income_table.filter((item) => {
    //     // this.checkedBoxes.includes(item.payment_type)
    //     // First condition: Check if item's payment type is included in the checked boxes
    //     const paymentTypeIncluded = this.checkedBoxes.includes(
    //       item.payment_type
    //     );

    //     // Additional condition for system_only: if system_only is true, ensure the item does not have an 'order' key
    //     const systemOnlyCondition =
    //       !this.system_only || !item.hasOwnProperty("order");

    //     // Return true if both conditions are met
    //     return paymentTypeIncluded && systemOnlyCondition;
    //   });
    // },
    // sumTableIn() {
    //   return this.incomeTableFilter.reduce((total, item) => {
    //     return item.side === "deposit" ? total + item.amount : total;
    //   }, 0);
    // },
    // sumTableOut() {
    //   return this.incomeTableFilter.reduce((total, item) => {
    //     return item.side === "withdraw" ? total + item.amount : total;
    //   }, 0);
    // },
  },
  data() {
    return {
      is_loading: false,
      ROLE: "owner",
      form: {
        date: {
          start: moment().format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
        },
        account_id: null,
      },
      single_active: false,
      transaction_table: [], /// รายการเดินบัญชี
      transaction_info: {
        sum_deposit: 0,
        sum_withdraw: 0,
      },
      income_table: [], /// ยอดภายใน
      income_info: {
        sum_deposit: 0,
        sum_withdraw: 0,
      },
      deposit_list_table: [], /// เงินประกัน
      damage_table: [], /// ของพังใช้รวมกับของขาย
      unassign_list_table: [], /// ยังไม่ได้ตัดยอด

      active_bank_account_id: null,
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      info: {
        avaliable_begin: 0,
      },
      avaliable_balance: 0,
      unassign_balance: 0,
      income_summary: {},
      transaction_summary: {},
      income_summary_whole: [],
      tab_select: "internal_transaction", // book_transaction , internal_transaction , deposit_list,damage_list, unassign_list
      transaction_fields: [
        { key: "time", label: "Time" },
        { key: "order", label: "Order" },
        { key: "detail", label: "รายละเอียด" },
        { key: "deposit", label: "เข้า" },
        { key: "withdraw", label: "ออก" },
        // { key: "cumsum", label: "คงเหลือ" },
        { key: "action", label: "Action" },
      ], /// หัวตาราง transaction
      checkbox: {
        rental: true,
        deposit: true,
        damage: true,
        shipping: true,
      },
      system_only: false,
    };
  },
  mounted() {
    /// เปลี่ยนมาใช้อันนี้ให้หมด
    this.$root.$on("order-reload", async () => {
      await this.loadAccount();
    });
  },
  created() {
    this.ROLE = getUserRole();
  },
  directives: {
    Ripple,
  },
  methods: {
    open_new(id) {
      window.open("/order/view/" + id, "_blank");
    },
    // summarizeIncome() {
    //   this.income_summary = this.income_table.reduce((acc, transaction) => {
    //     let { payment_type, amount, side } = transaction;

    //     if (!acc[payment_type]) {
    //       acc[payment_type] = { withdraw: 0, deposit: 0 };
    //     }
    //     acc[payment_type][side] += amount;
    //     return acc;
    //   }, {});
    // },
    summarizeTransactions() {
      this.transaction_summary = this.transaction_table.reduce(
        (acc, transaction) => {
          let { amount, side } = transaction;

          if (!acc[side]) {
            acc[side] = 0;
          }

          acc[side] += amount;
          return acc;
        },
        { withdraw: 0, deposit: 0 }
      );
    },
    reset_view() {
      this.single_active = false;
      this.active_bank_account_id = null;
    },
    view_detail(bank_account_id) {
      this.single_active = true;
      this.active_bank_account_id = bank_account_id;
    },

    load_summary() {
      if (this.form.account_id) {
        this.is_loading = true;
        this.$http({
          method: "POST",
          url: `/account/bank-account/day/income-sum-whole`,
          data: this.form,
        })
          .then((x) => {
            this.is_loading = false;
            const data = x.data.data;
            this.income_summary_whole = data.income_summary_whole;
            this.avaliable_balance = data.avaliable_balance;
            this.unassign_balance = data.unassign_balance;
          })
          .catch((error) => {
            this.is_loading = false;
            this.toast_error("มีข้อผิดพลาด", error.response.data.error);
          });
      }
    },

    loadAccount() {
      this.is_loading = true;
      if (this.tab_select === "book_transaction") {
        /// load transaction_table รายการเดินบัญชี
        this.$http({
          method: "POST",
          url: `/account/bank-account/day/book-transaction`,
          data: this.form,
        })
          .then((x) => {
            this.is_loading = false;
            const data = x.data.data;
            this.transaction_table = data.transaction_table;
            this.transaction_info = data.info;
            // this.info.avaliable_begin = data.info.avaliable_begin;
          })
          .catch((error) => {
            this.is_loading = false;
            this.toast_error("มีข้อผิดพลาด", error.response.data.error);
          });
      } else if (this.tab_select === "internal_transaction") {
        // ยอดภายใน
        this.form.checkbox = this.checkbox;
        this.form.system_only = this.system_only;
        this.$http({
          method: "POST",
          url: `/account/bank-account/day/internal-transaction`,
          data: this.form,
        })
          .then((x) => {
            this.is_loading = false;
            const data = x.data.data;
            this.income_table = data.income_table;
            this.income_info = data.info;
          })
          .catch((error) => {
            this.is_loading = false;
            this.toast_error("มีข้อผิดพลาด", error.response.data.error);
          });
      } else if (this.tab_select === "deposit_list") {
        this.$http({
          method: "POST",
          url: `/account/bank-account/day/deposit-list`,
          data: this.form,
        })
          .then((x) => {
            this.is_loading = false;
            const data = x.data.data;
            this.deposit_list_table = data.deposit_list_table;
          })
          .catch((error) => {
            this.is_loading = false;
            this.this.toast_error("มีข้อผิดพลาด", error);
            this.toast_error("มีข้อผิดพลาด", error.response.data.error);
          });
      } else if (this.tab_select === "damage_list") {
        this.form.payment_type = "damage";
        this.$http({
          method: "POST",
          url: `/account/bank-account/day/damage-list`,
          data: this.form,
        })
          .then((x) => {
            this.is_loading = false;
            const data = x.data.data;
            this.damage_table = data;
          })
          .catch((error) => {
            this.is_loading = false;
            this.this.toast_error("มีข้อผิดพลาด", error);
            this.toast_error("มีข้อผิดพลาด", error.response.data.error);
          });
      } else if (this.tab_select === "sale_list") {
        this.form.payment_type = "sale";
        this.$http({
          method: "POST",
          url: `/account/bank-account/day/damage-list`,
          data: this.form,
        })
          .then((x) => {
            this.is_loading = false;
            const data = x.data.data;
            this.damage_table = data;
          })
          .catch((error) => {
            this.is_loading = false;
            this.this.toast_error("มีข้อผิดพลาด", error);
            this.toast_error("มีข้อผิดพลาด", error.response.data.error);
          });
      } else if (this.tab_select === "unassign_list") {
        this.$http({
          method: "POST",
          url: `/account/bank-account/day/unassign-list`,
          data: this.form,
        })
          .then((x) => {
            this.is_loading = false;
            const data = x.data.data;
            this.unassign_list_table = data;
          })
          .catch((error) => {
            this.is_loading = false;
            this.this.toast_error("มีข้อผิดพลาด", error);
            this.toast_error("มีข้อผิดพลาด", error.response.data.error);
          });
      } else {
        this.is_loading = false;
      }
    },
    loadAccountv0() {
      console.log("v0");
      this.is_loading = true;
      this.$http({
        method: "POST",
        url: `/account/bank-account/day/v0`,
        data: this.form,
      })
        .then((x) => {
          this.is_loading = false;
          const data = x.data.data;
          this.income_summary_whole = data.income_summary_whole;

          // this.income_table = data.income_table; // ทำแล้ว
          // this.transaction_table = data.transaction_table; // ทำแล้ว
          // this.deposit_list_table = data.deposit_list_table; // ทำแล้ว
          // this.unassign_list_table = data.unassign_list_table; // ทำแล้ว
          this.info = data.info;
          // this.summarizeIncome();
          this.summarizeTransactions();
        })
        .catch((e) => {
          console.log("e", e);
          this.is_loading = false;
        });
    },
    account_picked(x) {
      this.form.account_id = x.bookbank_selected;
      this.load_summary();
      // this.loadAccount();
    },

    date_picked(x) {
      this.form.date = x;
      // this.loadAccount();
    },
    deleteTransaction(x) {
      if (x.confirm) {
        this.$http({
          method: "DELETE",
          url: `/payment/${x.data}`,
        }).then(async (x) => {
          await this.loadAccount();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "ลบการชำระเงินแล้ว",
              icon: "CheckIcon",
              text: "ลบรายการชำระเงินแล้ว",
              variant: "success",
            },
          });
        });
      }
    },
  },

  filters: {
    number: function (x) {
      if (x != null) {
        let rounded = Number(x).toFixed(2);

        // Remove the decimal part if it's .00
        if (rounded.endsWith(".00")) {
          rounded = rounded.substring(0, rounded.length - 3);
        }
        return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
    account_no: function (phone) {
      return phone.replace(/(\d{3})(\d{1})(\d{5})(\d+)/, "$1-$2-$3-$4");
    },
    day: function (date) {
      return moment(date).format("DD/MM");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
    p_type: function (x) {
      if (x === "deposit") {
        return "เงินประกัน";
      } else if (x === "advance_deposit") {
        return "เงินประกันล่วงหน้า";
      } else if (x === "rental") {
        return "รายรับ";
      } else if (x === "advance_rental") {
        return "รายรับล่วงหน้า";
      } else if (x === "shipping") {
        return "ค่าส่ง";
      } else if (x === "damage") {
        return "อุปกรณ์เสียหาย";
      } else if (x === "sale") {
        return "ขายอุปกรณ์";
      } else {
        return x;
      }
    },
  },
};
</script>

<style>
.table-responsive {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
</style>
