<template>
  <div>
    <!-- list เงินประกันที่ต้องคืนวันนี้ - มุมมอง order - มุมมอง payment -
    เงินประกันรวมมีเท่าไรใครบ้าง - เงินประกันอยู่บัญชีไหนเท่าไร -
    รายการมัดจำคืนวันนี้ตาม order <br />
    - รายการมัดจำคืนวันนี้ตาม Payment end_date <br />
    - -->
    <div class="row">
      <div class="col">
        <h1>เงินประกัน</h1>
        <b-card>
          <div class="row mb-2">
            <div class="col-12 col-md-8 text-left align-vertical-center">
              <date-account-selector @selected="account_picked">
              </date-account-selector>
            </div>
            <!-- v-if="ROLE === 'owner'" -->
            <div class="col-12 col-md-4 text-right">
              <b-form-group label="วันที่">
                <date-picker-range
                  @date_picked="date_picked"
                ></date-picker-range>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <!-- เงินเข้า / เงินออก -->
            <div class="col-md-3">
              <b-form-group label="SIDE">
                <div class="demo-inline-spacing">
                  <b-form-radio
                    v-model="form.side"
                    name="some-radios1"
                    value="deposit"
                    class="mt-0"
                    @change="loadData()"
                  >
                    เงินเข้า
                  </b-form-radio>
                  <b-form-radio
                    v-model="form.side"
                    name="some-radios1"
                    value="withdraw"
                    class="mt-0"
                    @change="loadData()"
                  >
                    เงินออก
                  </b-form-radio>
                </div>
              </b-form-group>
            </div>

            <div class="col-md-3">
              <b-form-group label="ประเภทเงินประกัน">
                <div class="demo-inline-spacing">
                  <b-form-radio
                    v-model="form.payment_type"
                    name="some-radios4"
                    value="deposit"
                    class="mt-0"
                    @change="loadData()"
                  >
                    เงินประกัน
                  </b-form-radio>
                  <b-form-radio
                    v-model="form.payment_type"
                    name="some-radios4"
                    value="advance_deposit"
                    class="mt-0"
                    @change="loadData()"
                  >
                    เงินประกันล่วงหน้า
                  </b-form-radio>
                </div>
              </b-form-group>
            </div>

            <!-- VIEW ORDER / PAYMENT -->
            <div class="col-md-3">
              <b-form-group label="VIEW">
                <div class="demo-inline-spacing">
                  <b-form-radio
                    v-for="x in view_options"
                    :key="x.id"
                    v-model="form.view"
                    name="some-radios2"
                    :value="x.value"
                    class="mt-0"
                    @change="loadData()"
                  >
                    {{ x.text }}
                  </b-form-radio>
                </div>
              </b-form-group>
            </div>

            <div class="col-md-3">
              <b-form-group label="สถานะ" v-if="form.view === 'order'">
                <div class="demo-inline-spacing">
                  <b-form-radio
                    v-for="x in pickup_status_options"
                    :key="x.id"
                    v-model="form.pickup"
                    name="some-radios3"
                    :value="x.value"
                    class="mt-0"
                    @change="loadData()"
                  >
                    {{ x.text }}
                  </b-form-radio>
                </div>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col text-right">
              <b-button
                variant="relief-success"
                class="btn-icon"
                @click="loadData()"
              >
                Search</b-button
              >
            </div>
          </div>
        </b-card>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th v-for="x in fields" :key="x.id">{{ x.label }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in deposit_from_order" :key="index">
                <td>
                  <b-button
                    variant="relief-success"
                    class="btn-icon"
                    @click="open_new(row._id)"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </td>
                <td>
                  {{ row.start_date | day }}
                  <b-badge variant="light-info">
                    {{ row.start_date | time }}
                  </b-badge>
                </td>
                <td>
                  {{ row.end_date | day }}
                  <b-badge variant="light-info">
                    {{ row.end_date | time }}
                  </b-badge>
                </td>
                <td>
                  <div v-if="row.member">
                    {{ row.member.retro_username }}
                    {{ row.member.gen_username }}
                  </div>
                </td>
                <td>{{ row.amount | number }}</td>
                <td v-if="form.view === 'order'">
                  <div v-for="x in row.payment" :key="x.id">
                    {{ x.amount | number }} <br />

                    <b-badge variant="light-danger" v-if="checkDiff(row) != 0">
                      {{ checkDiff(row) }}
                    </b-badge>
                  </div>
                </td>
                <td>
                  <div v-for="x in row.payment" :key="x.id" class="text-nowrap">
                    <span v-if="x.bookbank_info">
                      {{ x.bookbank_info.display_name }}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{ sum_total(deposit_from_order, "amount") | number }}</td>
                <td v-if="form.view === 'order'">
                  {{ totalPaymentAmount | number }}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BButton,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
import DatePickerRange from "./component/DatepickerRange.vue";
import DateAccountSelector from "./component/DateAccountSelector.vue";
import { getUserRole } from "@/auth/utils";
import moment from "moment";

export default {
  components: {
    BCard,
    BTable,
    BFormGroup,
    DateAccountSelector,
    DatePickerRange,
    BFormRadio,
    BFormRadioGroup,
    BButton,
    BBadge,
    BSpinner,
  },
  computed: {
    totalPaymentAmount() {
      return this.deposit_from_order.reduce((total, order) => {
        if (order.payment) {
          const paymentSum = order.payment.reduce(
            (sum, payment) => sum + payment.amount,
            0
          );
          return total + paymentSum;
        }
      }, 0);
    },
  },

  data() {
    return {
      ROLE: "user",
      form: {
        side: "deposit",
        view: "order",
        pickup: "all",
        date: {
          start: moment().format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
        },
        payment_type: "deposit",
      },
      view_options: [
        {
          text: "Order",
          value: "order",
        },
        {
          text: "Payment",
          value: "payment",
        },
      ],
      pickup_status_options: [
        {
          text: "ทั้งหมด",
          value: "all",
        },
        {
          text: "ยังไม่ได้คืน",
          value: "not-returned",
        },
      ],
      fields: [],
      deposit_from_order: [],
    };
  },
  created() {
    this.ROLE = getUserRole();
  },
  methods: {
    account_picked(x) {
      this.form.account_id = x.bookbank_selected;
      this.loadData();
    },
    date_picked(x) {
      this.form.date = x;
    },
    checkDiff(x) {
      const order_amount = x.amount;
      const payment_amount = x.payment.reduce((total, xx) => {
        return (total += xx.amount);
      }, 0);

      return order_amount - payment_amount;
    },
    loadData() {
      this.$http({
        method: "POST",
        url: `/account/deposit`,
        data: this.form,
      }).then((x) => {
        const data = x.data.data;
        this.fields = data.deposit_from_order_fields;
        this.deposit_from_order = data.deposit_from_order;
      });
    },
    open_new(id) {
      window.open("/order/view/" + id, "_blank");
    },
    sum_total(obj, key) {
      return obj.reduce((accum, o) => {
        if (o[key]) {
          return (accum += o[key]);
        }
      }, 0);
    },
  },
  filters: {
    day: function (date) {
      return moment(date).format("DD/MM");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
    number(x) {
      if (x != null) {
        x = parseFloat(x);
        // Check if the number is an integer
        if (Number.isInteger(x)) {
          // If it's an integer, return it without any decimal places
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          // If it's not an integer, format it to two decimal places
          return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      } else {
        return x;
      }
    },
  },
};
</script>
<style>
.table-responsive {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
</style>
