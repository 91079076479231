var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptions,"limit":80,"input-props":{
      id: 'autosuggest__input',
      class: 'form-control',
      state: 'false',
      placeholder: 'ค้นหาจาก แขวง เขต รหัสไปรษณีย์',
    },"variant":"danger","autocomplete":"off"},on:{"input":_vm.onInputChange,"selected":_vm.selectHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var suggestion = ref.suggestion;
return [_c('span',[_vm._v(_vm._s(suggestion.item.district))]),_vm._v(" => "),_c('span',[_vm._v(_vm._s(suggestion.item.amphoe))]),_vm._v(" => "),_c('span',[_vm._v(_vm._s(suggestion.item.province))]),_vm._v(" => "),_c('span',[_vm._v(_vm._s(suggestion.item.zipcode))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }