<template>
  <div>
    <div class="justify-content-between d-flex button-container">
      <h4>{{ title }}</h4>
    </div>
    <b-list-group flush>
      <b-list-group-item
        class="no-hover-bg"
        v-for="(x, index) in data"
        :key="index"
      >
        <div class="row">
          <div class="col-10">
            <div v-if="x.isEditing">
              <!-- Editable fields -->
              ชื่อบริษัท
              <b-form-input
                v-model="x.name"
                class="mb-1"
                placeholder="Enter name"
              />
              ที่อยู่

              <b-form-textarea
                v-model="x.address"
                rows="3"
                class="no-resize contact-address mb-1"
                style="height: 80px; overflow-y: auto; resize: none"
                placeholder="รายละเอียดที่อยู่"
              >
              </b-form-textarea>

              รหัสไปรษณีย์
              <b-form-input
                v-model="x.zipcode"
                class="mb-1"
                placeholder="Enter zipcode"
              />
              เลขประจำตัวผู้เสียภาษี
              <b-form-input
                v-model="x.tax_id"
                class="mb-1"
                placeholder="Enter tax ID"
              />
              <div class="d-flex">
                <b-button
                  size="sm"
                  variant="primary"
                  @click="saveEdit(index)"
                  class="mr-2"
                >
                  แก้ไข
                </b-button>
                <b-button
                  size="sm"
                  variant="secondary"
                  @click="cancelEdit(index)"
                >
                  ยกเลิก
                </b-button>
              </div>
            </div>
            <div v-else>
              <!-- Display fields -->
              <p class="mb-0">
                {{ x.name }} <br />
                {{ x.address }} {{ x.zipcode }} <br />
                เลขประจำตัวผู้เสียภาษี : {{ x.tax_id }}
                <br />
              </p>
            </div>
          </div>
          <div class="col-2">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="startEditing(index)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Edit</span>
              </b-dropdown-item>

              <b-dropdown-item @click="confirmDelete(index)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BCard,
  BListGroup,
  BInputGroup,
  BListGroupItem,
  BFormInput,
  BInputGroupAppend,
  BFormTextarea,
} from "bootstrap-vue";
export default {
  components: {
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormInput,
    BButton,
  },
  props: ["data", "title"],
  methods: {
    startEditing(index) {
      // Set isEditing state to true for the selected item
      this.$set(this.data[index], "isEditing", true);
    },
    saveEdit(index) {
      // Save changes and exit editing mode
      this.$set(this.data[index], "isEditing", false);
      const { _id, ...edit_form } = this.data[index];
      const billing_address_id = _id;
      // delete edit_form._id;
      this.$http({
        method: "POST",
        url: `/member/billing-address/update/${billing_address_id}`,
        data: edit_form,
      }).then((x) => {
        // this.load_data();
      });
    },
    cancelEdit(index) {
      // Reset the item to its previous state and exit editing mode
      this.$set(this.data[index], "isEditing", false);
    },
    confirmDelete(index) {
      this.$bvModal
        .msgBoxConfirm("ลบข้อมูลใบกำกับภาษี !!!", {
          title: "ยืนยันการลบข้อมูลใบกำกับภาษี",
          // size: 'sm',
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const billing_id = this.data[index]._id;
            this.$http({
              method: "POST",
              url: `/member/billing-address/delete/${billing_id}`,
            }).then((x) => {
              this.data.splice(index, 1);
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.no-hover-bg:hover {
  background-color: transparent !important;
}
</style>
