<template>
  <div>
    <b-modal
      id="modal-add-billing-address"
      ref="modal-add-billing-address"
      title="เพิ่มที่อยู่ออกใบกำกับภาษี"
      no-close-on-backdrop
      size="lg"
      hide-footer
    >
      <b-form @submit.prevent="addBillingAddress">
        <!-- ชื่อ -->
        <b-form-group label="ชื่อบริษัท">
          <b-form-input v-model="form.name" placeholder="ชื่อบริษัท" />
        </b-form-group>

        <!-- ที่อยู่ -->
        <b-form-group label="ที่อยู่">
          <b-form-textarea
            v-model="form.address"
            rows="3"
            class="no-resize contact-address"
            style="height: 80px; overflow-y: auto; resize: none"
            placeholder="รายละเอียดที่อยู่"
          >
          </b-form-textarea>
        </b-form-group>

        <!-- Zipcode -->
        <b-form-group label="รหัสไปรษณีย์">
          <b-form-input
            type="text"
            class="form-control contact-zipcode"
            placeholder="รหัสไปรษณีย์"
            v-model="form.zipcode"
          ></b-form-input>
        </b-form-group>

        <!-- เลขประจำตัวผู้เสียภาษี -->
        <b-form-group label="เลขประจำตัวผู้เสียภาษี">
          <b-form-input v-model="form.tax_id" />
        </b-form-group>

        <!-- สาขา / สำนักงานใหญ่ -->

        <b-form-group label="สาขา / สำนักงานใหญ่">
          <b-form-input v-model="form.branch" />
        </b-form-group>

        <b-button size="sm" @click="form.branch = 'สำนักงานใหญ่'"
          >สำนักงานใหญ่</b-button
        >

        <hr />

        <b-button variant="success" @click="AddBilling">
          เพิ่มที่อยู่
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BForm,
  BModal,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    BButton,
    BFormTextarea,
  },
  data() {
    return {
      form: {
        name: null,
        address: null,
        zipcode: null,
        tax_id: null,
        branch: null,
      },
    };
  },
  created() {},
  props: ["member_id"],
  methods: {
    AddBilling() {
      if (this.member_id) {
        this.form.member_id = this.member_id;
        this.$http({
          method: "POST",
          url: `/member/billing-address/add`,
          data: this.form,
        })
          .then((x) => {
            this.$refs["modal-add-billing-address"].hide();
            this.$emit("finished");
          })
          .catch((error) => {
            if (error.response) {
              const { data } = error.response;
              const { message } = data; // Destructure the 'data' from the response
              console.log(`Message: ${message}`);
              this.$refs["modal-add-billing-address"].hide();
              this.toast_error("มีข้อผิดพลาด", message);
            } else {
              this.toast_error("มีข้อผิดพลาด", error.message);
              this.$refs["modal-add-billing-address"].hide();
            }
          });
      }
    },
  },
};
</script>
