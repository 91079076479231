<template>
  <div class="doc-form-container clearfix">
    <!-- ข้อมูลลูกค้า -->
    <b-row class="project-row">
      <b-col>
        <!-- เลือกประเภท -->
        <b-form-group>
          <b-form-radio-group
            v-model="form.selectedType"
            :options="options"
            inline
          >
          </b-form-radio-group>
        </b-form-group>

        <div v-if="form.selectedType === 'person'">
          <!-- fields for บุคคล -->
        </div>

        <div v-if="form.selectedType === 'company'">
          <!-- fields for บริษัท -->
        </div>

        <div v-if="form.selectedType === 'company'">
          ข้อมูลออกใบกำกับภาษี
          <v-select
            v-model="billing_address_selected"
            label="title"
            :options="billing_address_list"
            :clearable="false"
            class="mb-1"
          />
          <b-button
            size="sm"
            class="mb-1"
            variant="success"
            @click="load_billing_address()"
          >
            Load
          </b-button>
        </div>

        <!-- ชื่อลูกค้า -->
        <b-form-group
          :label="
            form.selectedType === 'person' ? 'ชื่อ-นามสกุล' : 'ชื่อบริษัท'
          "
        >
          <b-form-input
            v-model="form.customer_name"
            type="text"
            pattern="\d*"
            data-model-name="reference"
            placeholder="ชื่อลูกค้า"
          />
        </b-form-group>

        <!-- ข้อมูลลูกค้า -->
        <b-form-group label="ที่อยู่">
          <b-form-textarea
            v-model="form.billing_address"
            rows="3"
            class="no-resize contact-address"
            style="height: 80px; overflow-y: auto; resize: none"
            placeholder="รายละเอียดที่อยู่"
          >
          </b-form-textarea>
        </b-form-group>
        <!-- Zipcode -->
        <b-form-group label="รหัสไปรษณีย์">
          <b-form-input
            type="text"
            maxlength="5"
            pattern="\s*"
            class="form-control contact-zipcode"
            placeholder="รหัสไปรษณีย์"
            v-model="form.zipcode"
            required
          ></b-form-input>
        </b-form-group>
        <!-- Tax ID -->
        <b-form-group label="เลขประจำตัวผู้เสียภาษี">
          <b-form-input
            type="text"
            maxlength="13"
            pattern="\s*"
            class="form-control contact-tax-id"
            placeholder="เลขประจำตัวผู้เสียภาษี"
            v-model="form.card_id"
            required
            @keydown="validateNumbersOnly"
          ></b-form-input>
        </b-form-group>
        <!-- Branch -->
        <b-form-group
          v-if="form.selectedType === 'company'"
          label="สำนักงาน/สาขาเลขที่"
        >
          <b-form-input
            type="text"
            class="form-control contact-branch"
            placeholder="สำนักงาน/สาขาเลขที่"
            v-model="form.branch"
            required
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <!-- <div class="text">จำนวนเงินรวมทั้งสิ้น</div>
        <div class="total-value"><h2>13123</h2></div> -->
        <!-- เลขที่ใบกำกับภาษี -->
        <h5 class="mb-2">TAX INVOICE No. {{ tax_invoice_no }}</h5>

        <!-- tax_invoice_no: "",
        yearmonth: "",
        invoice_number: "", -->
        <b-row>
          <b-col>
            <b-form-input
              autocomplete="off"
              class="border rounded"
              v-model="form.yearmonth"
              @change="get_lastest(form.yearmonth)"
            />
          </b-col>
          <b-col>
            <b-form-input
              autocomplete="off"
              class="border rounded"
              v-model="form.invoice_number"
            />
          </b-col>
        </b-row>

        <!-- วันที่ -->
        <b-form-group label="วันที่">
          <date-picker
            v-model="form.date"
            :masks="{ input: 'D/M/YYYY' }"
            :model-config="datepickerConfig"
            mode="date"
            @input="datepickerEvent"
          >
            <template v-slot="{ inputValue, togglePopover }">
              <!-- :mode="date-time" -->
              <b-form-input
                autocomplete="off"
                class="border px-2 rounded"
                :value="inputValue"
                @click="togglePopover()"
              />
            </template>
          </date-picker>
        </b-form-group>

        <b-row>
          <b-col>
            <!-- เอกสารอ้างอิง -->
            <b-form-group label="เอกสารอ้างอิง">
              <b-form-input
                v-model="form.order_id"
                type="text"
                pattern="\d*"
                data-model-name="reference"
                placeholder="ชื่อลูกค้า"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <!-- ผู้ขาย -->
            <b-form-group label="ผู้ขาย">
              <b-form-input
                v-model="form.seller_name"
                type="text"
                data-model-name="reference"
                placeholder="ผู้ขาย"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="form.selectedType === 'company'">
            <!-- ผู้ติดต่อ -->
            <b-form-group label="ผู้ติดต่อ">
              <b-form-input
                v-model="form.contact_name"
                type="text"
                data-model-name="reference"
                placeholder="ผู้ติดต่อ"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <!-- เบอร์โทร -->
            <b-form-group label="เบอร์โทร">
              <b-form-input
                v-model="form.contact_phone"
                type="text"
                pattern="\d*"
                data-model-name="reference"
                placeholder="เบอร์โทร"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- อุปกรณ์ -->
    <b-row class="mb-2">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th style="width: 50%">รายละเอียด</th>
            <th>
              <b-form-input type="text" v-model="form.quantity_text" />
              <small>จำนวน</small>
            </th>
            <!-- <th>จำนวน</th> -->
            <!-- <th>ราคาต่อหน่วย</th> -->
            <th>
              <b-form-input type="text" v-model="form.price_text" />
              <small>ราคาต่อหน่วย</small>
            </th>
            <th>คิดเป็นเงิน</th>
            <!-- <th>ลบ</th> -->
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, index) in form.items" :key="item._id">
            <td>{{ index + 1 }}</td>

            <td>
              <b-form-input type="text" v-model="item.item_name" />
            </td>
            <td>
              <b-form-input type="text" v-model="item.duration" />
            </td>
            <td>
              <b-form-input type="text" v-model="item.rental_price" />
            </td>
            <td>
              {{ computedTotal(item) }}
            </td>
          </tr>
        </tbody>
      </table>
    </b-row>
    <hr />
    <b-row>
      <b-col lg="6" cols="12">
        <!-- จำนวนเงินรวมทั้งสิ้นตัวหนังสือ -->
        <b-form-group>
          <h4>จำนวนเงินรวม ตัวหนังสือไทย</h4>
          <b-form-input
            type="text"
            class="form-control"
            placeholder="จำนวนเงินรวม ตัวหนังสือไทย"
            v-model="thaiBahtText"
            required
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="6" cols="12">
        <!-- รวมเป็นเงิน -->
        <div class="d-flex justify-content-between">
          <h4>รวมเป็นเงิน</h4>
          <h4>{{ display_sum_total | number }} บาท</h4>
        </div>

        <!-- ส่วนลด -->
        <div class="d-flex justify-content-between">
          <h4>ส่วนลด</h4>
          <h4>{{ display_discount | number }} บาท</h4>
        </div>

        <!-- จำนวนเงินหลังหักส่วนลด -->
        <div class="d-flex justify-content-between">
          <h4>จำนวนเงินหลังหักส่วนลด</h4>
          <b-form-input
            type="text"
            class="form-control ml-2 text-right"
            placeholder="จำนวนเงินหลังหักส่วนลด"
            v-model="after_discount_price"
            required
          >
          </b-form-input>
        </div>

        <!-- ภาษีมูลค่าเพิ่ม -->
        <div class="d-flex justify-content-between">
          <h4>ภาษีมูลค่าเพิ่ม</h4>
          <h4>{{ vat | number }} บาท</h4>
        </div>

        <!-- ราคาไม่รวมภาษีมูลค่าเพิ่ม -->
        <div class="d-flex justify-content-between">
          <h4>ราคาไม่รวมภาษีมูลค่าเพิ่ม</h4>
          <h4>{{ price_before_vat | number }} บาท</h4>
        </div>

        <!-- หัก ณ ที่จ่าย -->
        <div class="d-flex justify-content-between">
          <h4>% หัก ณ ที่จ่าย</h4>
          <b-form-radio-group
            v-model="form.selectedWithHolding"
            :options="options2"
            inline
          >
          </b-form-radio-group>
        </div>

        <!-- ยอดหัก ณ ที่จ่าย -->
        <div class="d-flex justify-content-between">
          <h4>หัก ณ ที่จ่าย</h4>
          <h4>{{ with_holding_price | number }}</h4>
        </div>

        <!-- ยอดชำระ -->
        <div class="d-flex justify-content-between">
          <h4>ยอดชำระ</h4>
          <h4>{{ after_with_holding_price | number }}</h4>
        </div>

        <!-- รูปแบบชำระเงิน -->
        <div class="d-flex justify-content-between mb-2">
          <h4>รูปแบบการชำระเงิน</h4>

          <div>
            <b-form-radio-group
              v-model="form.payment_method"
              :options="paymentOptions"
              inline
              class="mb-1"
              require
            >
            </b-form-radio-group>
            <!-- show_other_box -->
            <b-form-input
              type="text"
              class="form-control text-right"
              placeholder="ประเภทการชำระเงิน"
              v-model="other_payment_method"
              required
              v-if="show_other_box"
            >
            </b-form-input>
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <div></div>
          <!-- submitDisable -->
          <b-button
            variant="success"
            @click="createTaxInvoice()"
            :disabled="submitDisable"
          >
            <b-spinner v-if="submitDisable" small /> ออกใบกำกับภาษี
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import jwt_decode from "jwt-decode";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import {
  BAlert,
  BCard,
  BCardText,
  BCol,
  BRow,
  BButton,
  BFormInput,
  BFormRadioGroup,
  BFormRadio,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
export default {
  components: {
    BAlert,
    BCard,
    BCardText,
    BCol,
    BRow,
    BButton,
    BFormInput,
    BFormRadioGroup,
    BFormRadio,
    BFormGroup,
    BFormTextarea,
    DatePicker,
    vSelect,
  },

  computed: {
    display_sum_total() {
      // รวมเป็นเงิน รวมเลขจากตาราง
      if (this.form.items.length > 0) {
        const totalSum = this.form.items.reduce((sum, item) => {
          return sum + item.duration * item.rental_price;
        }, 0);
        return totalSum;
      }
    },
    show_other_box() {
      if (this.form.payment_method === "other") {
        return true;
      } else {
        return false;
      }
    },

    after_discount_price: {
      get() {
        // If there's a manual input, return that; otherwise, compute it normally
        return this.form.manual_after_discount_price !== null
          ? this.form.manual_after_discount_price
          : this.display_sum_total - this.form.discount_price;
      },
      set(value) {
        // When user changes the value, update the manual input tracker
        this.form.manual_after_discount_price = value;
      },
    },

    display_discount() {
      return this.display_sum_total - this.after_discount_price;
    },

    order_discount() {
      return this.form.discount_price;
    },

    price_before_vat() {
      // ราคาไม่รวมภาษีมูลค่าเพิ่ม
      const totalNetPrice = this.after_discount_price / 1.07;
      const roundedPrice = Math.round(totalNetPrice * 100) / 100; // Round to 2 decimal places
      return roundedPrice.toFixed(2); // Format as a string with 2 decimal places
    },

    vat() {
      const temp = this.after_discount_price - this.price_before_vat;
      return temp.toFixed(2);
    },

    thaiBahtText() {
      let Number = this.CheckNumber(this.after_discount_price);
      const NumberArray = [
        "ศูนย์",
        "หนึ่ง",
        "สอง",
        "สาม",
        "สี่",
        "ห้า",
        "หก",
        "เจ็ด",
        "แปด",
        "เก้า",
        "สิบ",
      ];
      const DigitArray = ["", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน", "ล้าน"];
      let BahtText = "";

      if (isNaN(Number)) {
        return "ข้อมูลนำเข้าไม่ถูกต้อง";
      } else if (Number > 9999999.9999) {
        return "ข้อมูลนำเข้าเกินขอบเขตที่ตั้งไว้";
      } else {
        Number = Number.split(".");
        if (Number[1] && Number[1].length > 0) {
          Number[1] = Number[1].substring(0, 2);
        }
        let NumberLen = Number[0].length;
        for (let i = 0; i < NumberLen; i++) {
          let tmp = Number[0].substring(i, i + 1);
          if (tmp !== "0") {
            if (i === NumberLen - 1 && tmp === "1") {
              BahtText += "เอ็ด";
            } else if (i === NumberLen - 2 && tmp === "2") {
              BahtText += "ยี่";
            } else if (i === NumberLen - 2 && tmp === "1") {
              BahtText += "";
            } else {
              BahtText += NumberArray[parseInt(tmp)];
            }
            BahtText += DigitArray[NumberLen - i - 1];
          }
        }
        BahtText += "บาท";
        if (!Number[1] || Number[1] === "0" || Number[1] === "00") {
          BahtText += "ถ้วน";
        } else {
          let DecimalLen = Number[1].length;
          for (let i = 0; i < DecimalLen; i++) {
            let tmp = Number[1].substring(i, i + 1);
            if (tmp !== "0") {
              if (i === DecimalLen - 1 && tmp === "1") {
                BahtText += "เอ็ด";
              } else if (i === DecimalLen - 2 && tmp === "2") {
                BahtText += "ยี่";
              } else if (i === DecimalLen - 2 && tmp === "1") {
                BahtText += "";
              } else {
                BahtText += NumberArray[parseInt(tmp)];
              }
              BahtText += DigitArray[DecimalLen - i - 1];
            }
          }
          BahtText += "สตางค์";
        }
        return BahtText;
      }
    },

    tax_invoice_no() {
      const invoiceNumberString = this.form.invoice_number
        .toString()
        .padStart(4, "0");
      return "L" + this.form.yearmonth + "" + invoiceNumberString;
    },

    with_holding_price() {
      let with_holding_price =
        (this.price_before_vat / 100) * this.form.selectedWithHolding;
      with_holding_price = Math.round(with_holding_price * 100) / 100;
      return with_holding_price;
    },

    after_with_holding_price() {
      return (this.after_discount_price - this.with_holding_price).toFixed(2);
    },
  },
  data() {
    return {
      billing_address_list: [],
      form: {
        selectedType: "person",
        selectedWithHolding: 0,
        date: new Date().toISOString().substr(0, 10),
        // booking: [],
        items: [],
        customer_name: null,
        billing_address: null,
        quantity_text: "ระยะเวลา (วัน)",
        price_text: "ราคาต่อวัน",
        yearmonth: "",
        invoice_number: "",
        branch: "สำนักงานใหญ่",
        rental_price: 0,
        discount_price: 0,
        manual_after_discount_price: 0,
        payment_method: "โอนเข้าบัญชี",
      },
      datepickerConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      options: [
        { text: "บุคคล", value: "person" },
        { text: "บริษัท", value: "company" },
      ],
      options2: [
        { text: "ไม่", value: 0 },
        { text: "1%", value: 1 },
        { text: "3%", value: 3 },
        { text: "5%", value: 5 },
      ],
      item: {},
      submitDisable: false,
      order_data: {},
      paymentOptions: [
        { text: "โอน", value: "โอนเข้าบัญชี" },
        { text: "เงินสด", value: "เงินสด" },
        { text: "บัตรเครดิต", value: "บัตรเครดิต" },
        { text: "อื่นๆ", value: "other" },
      ],
      other_payment_method: null,
      select: {
        day: {
          title: null,
          value: null,
        },
      },
      day_list: [],
      billing_address_selected: [],
    };
  },
  created() {
    this.get_order();
    this.get_lastest(this.getCurrentDate());

    // for (let i = 0; i <= 100; i = i + 0.1) {
    //   console.log(i, this.testthaiBahtText(i));
    // }
  },

  methods: {
    CheckNumber(Number) {
      let decimal = false;
      Number = Number.toString();
      Number = Number.replace(/ |,|บาท|฿/gi, "");
      for (let i = 0; i < Number.length; i++) {
        if (Number[i] === ".") {
          decimal = true;
          break;
        }
      }
      if (!decimal) {
        Number += ".00";
      }
      return Number;
    },

    async get_order() {
      const accessToken = localStorage.getItem("accessToken");
      const jwt_decoded = jwt_decode(accessToken);

      const member = await this.$http({
        method: "GET",
        url: `/member/view/${jwt_decoded.id}`,
      });
      const m = member.data.data;

      await this.$http({
        method: "GET",
        url: `/order/print/${this.$route.params.id}`,
      })
        .then(async (x) => {
          // this.form.booking = x.data.data.booking;
          const d = x.data.data;
          this.order_data = d;
          this.form.customer_name =
            d.member.first_name + " " + d.member.last_name;

          this.form.billing_address = d.member.address1;
          this.form.zipcode = d.member.zipcode;
          this.form.card_id = d.member.card_id;
          this.form.order_id = d.order_id;
          this.form.seller_name = m.first_name;
          this.form.contact_phone = d.member.phone;
          this.form.total_net_price = d.total_net_price;
          this.form.yearmonth = this.getCurrentDate();
          this.form.rental_price = d.rental_price;
          this.form.discount_price = d.discount_price;
          this.form.manual_after_discount_price =
            this.form.rental_price - this.form.discount_price;

          this.form.is_payment_method_other = this.show_other_box;
          if (this.form.payment_method === "other") {
            this.form.payment_method = this.other_payment_method;
          }
          this.form.items = await d.booking.map((x) => {
            const y = {};
            y.item_name = "ค่าเช่า " + x.product.lease_name;
            y.duration = x.product.duration;
            y.rental_price = x.product.rental_price;
            console.log("item", x);
            return y;
          });

          if (d.member.province === "กรุงเทพมหานคร") {
            this.form.billing_address = `${d.member.address1} ถนน ${d.member.road} แขวง ${d.member.district} เขต ${d.member.amphoe} ${d.member.province}`;
          } else {
            this.form.billing_address = `${d.member.address1} ถนน ${d.member.road} ตำบล ${d.member.district} อำเภอ ${d.member.amphoe} จังหวัด ${d.member.province} `;
          }

          const billing_temp = d.member.billing;
          // this.billing_address_list =

          this.billing_address_list = billing_temp.map((x) => {
            const z = {};
            z.name = x.name;
            z.value = JSON.parse(JSON.stringify(x));
            z.title = `${x.name} \n${x.address}`;
            z.key = x._id;
            return z;
          });
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    async get_lastest(yearmonth) {
      const lastest_invoice_number = await this.$http({
        method: "POST",
        url: `/tax-invoice/lastest/${yearmonth}`,
      });

      this.form.invoice_number = lastest_invoice_number.data.data;
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to the month because months are zero-based

      return `${year}${month}`;
    },

    async checkInvoiceDuplicate() {
      return this.$http({
        method: "post",
        url: `/tax-invoice/check-exist/`,
        data: {
          tax_invoice_no: this.tax_invoice_no,
        },
      })
        .then((x) => {
          return x.data.data;
        })
        .catch(() => {
          return true;
        });
    },

    async createTaxInvoice() {
      const check_dup = await this.checkInvoiceDuplicate();

      if (check_dup === false) {
        this.submitDisable = true;
        this.form.total_price = this.display_sum_total; //รวมเป็นเงิน
        this.form.discount = this.display_discount; // ส่วนลด
        this.form.total_net_price = this.after_discount_price; // จำนวนเงินหลังหักส่วนลด
        this.form.vat = this.vat; // ภาษีมูลค่าเพิ่ม
        this.form.price_before_vat = this.price_before_vat; // ราคาไม่รวมภาษีมูลค่าเพิ่ม
        this.form.with_holding_price = this.with_holding_price; // ยอด หัก ณ ที่จ่าย
        this.form.selectedWithHolding = this.form.selectedWithHolding; // % หัก ณ ที่จ่าย
        this.form.after_with_holding_price = this.after_with_holding_price; // ยอดชำระ
        this.form.price_after_vat =
          parseFloat(this.price_before_vat) + parseFloat(this.vat);

        this.form.thaiBahtText = this.thaiBahtText;
        this.form.full_order_id = this.$route.params.id;
        this.form.tax_invoice_no = this.tax_invoice_no;

        this.$http({
          method: "post",
          url: `/tax-invoice/create/`,
          data: this.form,
        })
          .then((x) => {
            this.$router.push({
              name: "tax-invoice-print",
              params: { id: x.data.data._id },
            });
          })
          .catch(() => {
            this.submitDisable = false;
          });
      } else {
        const lastest_num = await this.$http({
          method: "POST",
          url: `/tax-invoice/lastest/${this.form.yearmonth}`,
        }).then((x) => {
          return x.data.data;
        });
        this.toast_error("เลขที่ใบกำกับภาษีซ้ำ", `เลขที่ใช้ได้ ${lastest_num}`);
      }
    },
    computedTotal(item) {
      const total = parseFloat(item.duration) * parseFloat(item.rental_price);
      return total.toFixed(2);
    },
    datepickerEvent() {
      this.form.yearmonth = this.form.date.replace("-", "").slice(0, 6);
      this.get_lastest(this.form.yearmonth);
    },
    validateNumbersOnly() {
      // tax_id_checker
    },
    load_billing_address() {
      const xx = this.billing_address_selected.value;
      this.form.customer_name = xx.name;
      this.form.billing_address = xx.address;
      this.form.zipcode = xx.zipcode;
      this.form.card_id = xx.tax_id;
      this.form.branch = xx.branch;
    },
  },
  filters: {
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.my-table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}
.my-table th,
.my-table td {
  padding-left: 20px !important;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}
.my-table th:first-child,
.my-table td:first-child {
  border-left: 1px solid black;
}
.my-table th:last-child,
.my-table td:last-child {
  border-right: 1px solid black;
}
</style>
