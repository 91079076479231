<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4>เงินประกันในระบบ</h4>
      <div>
        <h4>รวมเงินประกัน: {{ sum_deposit | number }} บาท</h4>
      </div>
    </div>
    <table class="table mt-1">
      <tbody>
        <tr>
          <td>Order</td>
          <td>วันที่รับ</td>
          <td>วันที่คืน</td>
          <td>Member</td>
          <td>ยอดเงิน</td>
        </tr>
        <tr v-for="(y, index) in data" :key="index">
          <td>
            <div v-if="y.order">
              <div>
                <b-button
                  variant="relief-success"
                  class="btn-icon"
                  @click="open_new(y.order_id)"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </div>
              <div>
                <small>{{ y.order.order_id }}</small>
              </div>
            </div>
          </td>
          <td>
            <div v-if="y.order">
              {{ y.order.start_date | day }}
              <b-badge variant="light-info">
                {{ y.order.start_date | time }}
              </b-badge>
            </div>
          </td>
          <td>
            <div v-if="y.order">
              {{ y.order.end_date | day }}
              <b-badge variant="light-info">
                {{ y.order.end_date | time }}
              </b-badge>
            </div>
          </td>
          <td>
            <div v-if="y.member">
              <div>
                {{ y.member.first_name }}
                {{ y.member.last_name }}
                <span v-if="y.member.retro_username">
                  ( {{ y.member.retro_username }} )
                </span>
                <span v-else-if="y.member.gen_username">
                  ( {{ y.member.gen_username }} )
                </span>
              </div>
              <!-- {{ y.member.first_name }}
              {{ y.member.last_name }} -->
              <!-- <div v-if="y.member.retro_username">
                ( {{ y.member.retro_username }} )
              </div>
              <div v-else-if="y.member.gen_username">
                {{ y.member.gen_username }}
              </div> -->
            </div>
            <!-- d:{{ y.description }} n:{{ y.note }} -->
            <span v-if="y.description">{{ y.description }} (d)</span>
            <span v-if="y.note">n:{{ y.note }} (n)</span>
          </td>
          <td>{{ y.difference | number }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import { BButton, BBadge } from "bootstrap-vue";
export default {
  components: {
    BButton,
    BBadge,
  },
  data() {
    return {
      sum_deposit: 0,
    };
  },
  methods: {
    open_new(id) {
      window.open("/order/view/" + id, "_blank");
    },
    summ() {
      this.sum_deposit = this.data.reduce((sum, item) => {
        if (item.order_id != null) {
          return sum + item.difference;
        }
        return sum;
      }, 0);
    },
  },
  props: ["data", "mode"],
  watch: {
    // Watcher for the 'data' prop
    data(newVal, oldVal) {
      this.summ(); // Call the method you want to run
    },
  },
  filters: {
    number(x) {
      if (x != null) {
        x = parseFloat(x);
        if (Number.isInteger(x)) {
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      } else {
        return x;
      }
    },
    day: function (date) {
      return moment(date).format("DD/MM");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
  },
};
</script>
