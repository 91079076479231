<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4>การชำระเงิน</h4>
    </div>
    <b-card no-body>
      <b-row>
        <b-col>
          <b-table
            :fields="fields"
            :items="items"
            class="mb-0"
            :responsive="true"
          >
            <!-- รายละเอียด -->
            <template #cell(payment_type)="data">
              {{ data.item.payment_type_thai_text }}
            </template>

            <!-- เข้า ออก -->
            <template #cell(deposit_withdraw)="data">
              <span>{{ data.item.deposit | number }} </span>
              <span v-if="data.item.withdraw > 0">
                - {{ data.item.withdraw | number }}</span
              >
            </template>

            <!-- จ่ายแล้ว -->
            <template #cell(left)="data">
              <div>
                {{ data.item.left | number }}
              </div>
            </template>

            <!-- column ยอดเงิน order -->
            <template #cell(order_amount)="data">
              <div>
                {{ data.item.order_price | number }}
              </div>
            </template>

            <template #cell(payment_status)="data">
              <div
                v-if="
                  data.item.order_price > 0 &&
                  data.item.payment_type !== 'deposit'
                "
              >
                <b-badge variant="success" v-if="data.item.pending_price === 0"
                  >ครบแล้ว
                </b-badge>
                <b-badge
                  variant="danger"
                  v-else-if="data.item.pending_price > 0"
                  >ยังไม่ครบ</b-badge
                >
                <b-badge variant="danger" v-else>Error</b-badge>
              </div>
              <div v-if="data.item.payment_type === 'deposit'">
                <b-badge :variant="deposit_status(data.item).variant">
                  {{ deposit_status(data.item).text }}
                </b-badge>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>

    <transaction-modal :info="info" />

    <add-payment-modal :info="info" />
    <!-- <withdraw-modal /> -->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BBadge,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AddPaymentModal from "./AddPaymentModal.vue";
import TransactionModal from "./TransactionModal.vue";
// import WithdrawModal from './WithdrawModal.vue'

import axios from "axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    AddPaymentModal,
    VBModal,
    TransactionModal,
    // WithdrawModal
  },
  props: ["info"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  mounted() {
    this.$root.$on("order-reload", async () => {
      await this.load_payment();
    });
  },
  data() {
    return {
      fields: [
        { key: "payment_type", label: "รายละเอียด" },
        { key: "deposit_withdraw", label: "เข้า - ออก" },
        { key: "left", label: "เงินใน Payment" },
        { key: "order_amount", label: "ยอดที่ต้องชำระทั้งหมด (ORDER)" },
        { key: "pending_price", label: "ค้างชำระ" },
        { key: "payment_status", label: "สถานะ" },
      ],
      items: [],
    };
  },
  created() {
    this.load_payment();
  },
  methods: {
    deposit_status(data) {
      if (data.pending_price === 0 && data.order_price > 0) {
        return {
          text: "ชำระแล้ว",
          variant: "success",
        };
      } else if (
        data.left === 0 &&
        data.pending_price > 0 &&
        data.deposit > 0
      ) {
        return {
          text: "คืนแล้ว",
          variant: "info",
        };
      } else if (data.pending_price > 0 && data.order_price > 0) {
        return {
          text: "ยังไม่ครบ",
          variant: "danger",
        };
      } else {
        return {
          text: `Error `,
          variant: "danger",
        };
      }
    },
    comma(x) {
      if (x != null) {
        let rounded = Number(x).toFixed(2);

        // Remove the decimal part if it's .00
        if (rounded.endsWith(".00")) {
          rounded = rounded.substring(0, rounded.length - 3);
        }
        return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
    async load_payment() {
      await this.$http({
        method: "POST",
        url: `/payment/order/summary`,
        data: {
          order_id: this.$route.params.id,
        },
      }).then((x) => {
        this.items = x.data.data;
      });
    },
  },
  filters: {
    number: function (x) {
      if (x != null) {
        let rounded = Number(x).toFixed(2);

        // Remove the decimal part if it's .00
        if (rounded.endsWith(".00")) {
          rounded = rounded.substring(0, rounded.length - 3);
        }
        return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>
