<template>
  <div>
    <b-overlay variant="dark" :show="loading" rounded="sm">
      <b-card v-if="filter_data.length == 0">
        <h4>ไม่มี Order รอยอดวางประกัน</h4>
      </b-card>
      <div v-if="!loading">
        <b-card v-for="x in filter_data" :key="x._id">
          <b-row>
            <b-col md="5" class="mb-2">
              <div class="mb-1">
                <div class="order_id">
                  <b-link
                    :to="{ name: 'order-view', params: { id: x._id } }"
                    class="font-weight-bold"
                    ><u>Order ID: {{ x.order_id }}</u></b-link
                  >
                </div>
              </div>
              <h5>ข้อมูลผู้เช่า</h5>
              <!-- {{filter_data[0].member_id}} -->
              <div v-if="x.member">
                ชื่อ:
                <b-link
                  :to="{ name: 'member-view', params: { id: x.member_id } }"
                  class="font-weight-bold"
                  target="_blank"
                >
                  {{ x.member.first_name }} {{ x.member.last_name }} <br />
                </b-link>
                โทร: {{ x.member.phone }} <br />
                Note: {{ x.member.note }} <br />
                <div v-if="days_since_register(x.member) < 4">
                  <b-badge variant="danger">
                    <p style="font-size: 15px" class="mb-0">
                      อายุสมาชิก {{ days_since_register(x.member) }} วัน !!
                    </p>
                  </b-badge>
                  <br />
                </div>
                <div class="row mt-1" v-if="x.member.billing.length">
                  <div class="col">
                    <b-button size="sm" @click="show_billing = !show_billing">
                      ข้อมูลใบกำกับ
                    </b-button>
                    <div v-if="show_billing" class="mt-2">
                      <div v-for="(y, index) in x.member.billing" :key="index">
                        {{ y.name }} <br />
                        {{ y.address }} {{ y.zipcode }} <br />
                        {{ y.tax_id }}
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <black-list-check :member="x.member" />
              </div>
              <div v-else>ไม่มีข้อมูลผู้เช่า</div>
              <h5>อุปกรณ์ที่เช่า</h5>
              <b-list-group flush>
                <b-list-group-item v-for="y in x.booking" :key="y._id">
                  <div class="d-flex justify-content-between">
                    <div>
                      <div>
                        <b class="mr-1">{{ y.product.name }}</b>
                        <span v-if="y.add_from_kit">
                          <a @click="open_kit_product(y.from_product_kit_id)">
                            <b-badge variant="success">จัดชุด</b-badge>
                          </a>
                        </span>
                      </div>
                      <div>
                        ( {{ y.product.deposit_1 | number }} ) (
                        {{ y.product.deposit_2 | number }} )
                      </div>
                    </div>
                    <div>{{ y.price | number }} บาท</div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div
                    class="d-flex justify-content-between"
                    v-if="x.shipping_price"
                  >
                    <b>ค่าส่ง</b>
                    {{ x.shipping_price }} บาท
                  </div>
                  <div class="text-right">
                    <b>รวมค่าเช่า: &nbsp;&nbsp;&nbsp;&nbsp;</b>
                    {{ x.rental_price | number }} บาท
                  </div>
                  <div class="text-right">
                    <b>รวมแบบ 1 - &nbsp;&nbsp;&nbsp;&nbsp;</b>
                    {{ sum_deposit(x.booking, "deposit_1") | number }} บาท
                  </div>
                  <div class="text-right">
                    <b>รวมแบบ 2 - &nbsp;&nbsp;&nbsp;&nbsp;</b>
                    {{ sum_deposit(x.booking, "deposit_2") | number }} บาท
                  </div>
                  <div class="text-right">
                    <b>รวมราคาอุปกรณ์ - &nbsp;&nbsp;&nbsp;&nbsp;</b>
                    {{ sum_deposit(x.booking, "lease_price") | number }} บาท
                  </div>
                </b-list-group-item>
              </b-list-group>
              <hr />
            </b-col>
            <b-col md="3">
              <h5>ข้อมูลการเช่า: {{ x.order_id }}</h5>
              <b-row>
                <b-col class="text-center">
                  <b-card border-variant="secondary">
                    <h4>รับของ</h4>
                    <h4>{{ x.start_date | day }}</h4>
                    <div>หลัง {{ x.start_date | time }}</div>
                  </b-card>
                </b-col>
                <b-col class="text-center">
                  <b-card border-variant="secondary">
                    <h4>คืนของ</h4>
                    <h4>{{ x.end_date | day }}</h4>
                    <div>ก่อน {{ x.end_date | time }}</div>
                  </b-card>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div>
                    <span>การรับของ: </span>
                    <b-badge variant="success">{{
                      x.pickup_method_text.text
                    }}</b-badge>
                  </div>
                  <div>
                    <span>จำนวนวัน: </span>
                    {{ x.duration }} วัน
                  </div>
                  <div>
                    <span>ตัวคูณค่าเช่า: </span>
                    x{{ x.multiplier }}
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="4">
              <div class="demo-inline-spacing mb-2">
                <b-form-radio-group v-model="require_advance_transfer[x._id]">
                  <b-form-radio name="some-radio1" :value="false">
                    ชำระเต็มหน้าร้าน
                  </b-form-radio>
                  <b-form-radio name="some-radio1" :value="true">
                    โอนล่วงหน้า
                  </b-form-radio>
                </b-form-radio-group>
              </div>
              <div v-if="require_advance_transfer[x._id] === true">
                <h5>ยอดที่ต้องโอนล่วงหน้า</h5>
                <div class="row">
                  <div class="col-sm-6">
                    <b-form-input
                      v-model="advance_transfer_price[x._id]"
                      class="mb-2"
                      autocomplete="off"
                    />
                  </div>
                  <div class="col-sm mb-1">
                    <b-button
                      class="mr-1"
                      @click="cal(x.rental_price, x._id, 30)"
                      variant="success"
                    >
                      30%
                    </b-button>
                    <b-button
                      @click="cal(x.rental_price, x._id, 50)"
                      variant="success"
                    >
                      50%
                    </b-button>
                  </div>
                </div>
              </div>

              <b-form-group label="ยอดวางประกัน" label-for="basicInput">
                <b-form-input
                  id="basicInput"
                  placeholder="Deposit Amount"
                  v-model="deposit_price[x._id]"
                  type="number"
                />
              </b-form-group>

              <label for="textarea-default">Note วางประกัน (ADMIN)</label>
              <b-form-textarea
                id="textarea-default"
                placeholder="Textarea"
                rows="3"
                v-model="note_deposit[x._id]"
              />
              <b-button
                class="mt-2"
                variant="success"
                @click="save_deposit(x._id)"
                >Submit</b-button
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h5>รายการจองตอนนี้</h5>
              <booking-member
                v-if="x.member"
                :member_id="x.member._id"
                :order_id="x._id"
              />
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay,
  BLink,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  BFormInput,
  BFormRadioGroup,
  BFormGroup,
  BCard,
  BBadge,
  BCardText,
  BRow,
  BCol,
  BTab,
  BTabs,
  BButton,
  BFormRadio,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import FilterTable from "../component/FilterTable.vue";
import BookingMember from "../../components/BookingFromMemberid.vue";
import BlackListCheck from "../../member/component/BlackListCheck.vue";

import moment from "moment";
import store from "@/store/index";
import { getUserRole } from "@/auth/utils";

export default {
  components: {
    BOverlay,
    BLink,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BFormInput,
    BCard,
    BCardText,
    BRow,
    BCol,
    BBadge,
    BTab,
    BTabs,
    BButton,
    VueGoodTable,
    FilterTable,
    BFormRadioGroup,
    BFormRadio,
    BFormGroup,
    BookingMember,
    BlackListCheck,
  },
  data() {
    return {
      require_advance_transfer: {},
      deposit_price: {},
      note_deposit: {},
      advance_transfer_price: {},
      text: this.$route.params.text,
      filter_data: [],
      form: {
        side: "status",
        order_status: ["pending-deposit"],
      },
      loading: true,
      show_billing: false,
      // lock_options: [
      //     { text: 'ล็อคคิว', value: 'pending-deposit' },
      //     { text: 'ไม่ได้ล็อคคิว', value: 'pending-confirm-no-lock' },
      // ],
      ROLE: "null",
    };
  },

  mounted() {
    this.ROLE = getUserRole();
    if (this.ROLE !== "owner") {
      this.$router.push("/");
    }
  },
  created() {
    this.load_data();
  },
  methods: {
    open_kit_product(product_kit_id) {
      const url = `/product/view/${product_kit_id}`;
      window.open(url, "_blank");
    },
    cal(rental_price, order_id, pct) {
      const calculatedValue =
        Math.floor((rental_price * (pct / 100)) / 10) * 10;
      this.$set(this.advance_transfer_price, order_id, calculatedValue);
    },

    days_since_register(x) {
      const startDate = new Date(x.createdAt);
      const today = new Date();
      const diffTime = Math.abs(today - startDate);
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // Convert time difference to days
      return diffDays;
    },
    sum_deposit(obj, key) {
      return _.sumBy(obj, `product.${key}`, 0);
    },
    load_data() {
      this.form.selected_page = 1;
      this.form.per_page = 100;
      this.$http({
        method: "POST",
        url: `/order/list/filter`,
        data: this.form,
      }).then((x) => {
        this.filter_data = x.data.data;
        // for (x of this.filter_data) {
        //   this.deposit_price[x._id] = x.deposit_price;
        //   this.note_deposit[x._id] = x.note_deposit;
        // }
        this.loading = false;
        this.filter_data.forEach((item) => {
          if (!this.require_advance_transfer[item._id]) {
            this.$set(this.require_advance_transfer, item._id, false);
          }
        });
      });
    },
    save_deposit(order_id) {
      // ทำ log
      const bookings = this.filter_data.find((x) => x._id == order_id).booking;

      this.$http({
        method: "PUT",
        url: `/order/update-deposit/` + order_id,
        data: {
          bookings: bookings,
          deposit_price: this.deposit_price[order_id],
          note_deposit: this.note_deposit[order_id],
          require_advance_transfer: this.require_advance_transfer[order_id],
          advance_transfer_price: this.advance_transfer_price[order_id],
          order_status: "pending-call",
          ready_to_call: true,
        },
      })
        .then((x) => {
          console.log("save_deposit", x);
          this.filter_data = this.filter_data.filter(
            (item) => item._id !== order_id
          );

          this.toast(
            "บันทึกยอดวางประกัน",
            "Order:" +
              x.data.data.order_id +
              " บันทึกยอดวางประกันเรียบร้อยแล้ว",
            "success"
          );

          //   this.load_data();
        })
        .catch((error) => {
          console.log(error.response.data.error);
          this.toast(
            "ผิดพลาด",
            "มีข้อผิดพลาด " + error.response.data.error,
            "danger"
          );
        });
    },
    toast(title, body, variant) {
      this.$bvToast.toast(body, {
        title: `${title}`,
        toaster: "b-toaster-top-right",
        variant: variant,
        solid: true,
        appendToast: false,
      });
    },
  },
  filters: {
    day: function (date) {
      return moment(date).format("D/M/YY");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
    number(x) {
      if (x != null) {
        x = parseFloat(x);
        // Check if the number is an integer
        if (Number.isInteger(x)) {
          // If it's an integer, return it without any decimal places
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          // If it's not an integer, format it to two decimal places
          return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      } else {
        return x;
      }
    },
  },
};
</script>
<style>
.order_id {
  font-size: 1.2rem;
}
</style>
