<template>
  <div>
    <b-card>
      <b-button
        class="mr-1"
        variant="relief-warning"
        size="sm"
        @click="load_images()"
        :disabled="loading_gen_image"
        ><b-spinner v-if="loading_gen_image" small />
        <feather-icon icon="ImageIcon" /> Gen รูป
      </b-button>
      <div v-show="show_canvas">
        <div class="row">
          <div class="col-md-8">
            <canvas
              class="mt-1"
              ref="canvas"
              width="400"
              height="400"
              style="border: 1px solid #ccc"
            >
            </canvas>
          </div>
          <div class="col-md-4">
            <b-button class="mt-2" @click="saveCanvasAsImage"
              >Save Canvas as Image</b-button
            >
            <b-button class="mt-2 ml-2" @click="rotateSelectedObject"
              >Rotate Selected 90°
            </b-button>
            <b-button class="mt-2 ml-2" @click="bringToFront"
              >Bring Forward</b-button
            >
            <b-button class="mt-2 ml-2" @click="sendToBack"
              >Send Backward</b-button
            >
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { fabric } from "fabric";
import { BCard, BButton, BSpinner } from "bootstrap-vue";

export default {
  props: {
    images: {
      type: Array,
      required: true, // Ensure images prop is provided
    },
    name: {
      type: String,
    },
  },
  data() {
    return {
      loading_gen_image: false,
      canvas: null, // Store the Fabric.js canvas instance
      show_canvas: false,
    };
  },
  components: {
    BButton,
    BSpinner,
    BCard,
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.canvas) {
        this.canvas = new fabric.Canvas(this.$refs.canvas, {
          width: 400,
          height: 400,
          preserveObjectStacking: true,
        });
        // Add a guideline
        this.addGuidelineWithLabel(400 - 54, "lens set");
        this.addGuidelineWithLabel(400 - 125, "camera set");
        // this.addGuidelineWithLabel(300, "Line 3");
      } else {
        console.error("Canvas element not found!");
      }
    });
  },
  methods: {
    load_images() {
      this.show_canvas = true;
      this.loading_gen_image = true;
      this.$http({
        method: "POST",
        url: `/product/gen-image-kit`,
        data: {
          images: this.images,
        },
      })
        .then((response) => {
          this.loading_gen_image = false;
          const images = response.data.data;
          images.forEach((image) => {
            const base64Image = `data:${image.contentType};base64,${image.data}`;
            fabric.Image.fromURL(base64Image, (img) => {
              // Calculate scale for 400x400 UI
              const scaleFactor = 0.5; // Since 400/800 = 0.5
              img.set({
                scaleX: scaleFactor,
                scaleY: scaleFactor,
                left: img.left * scaleFactor || 0,
                top: img.top * scaleFactor || 0,
              });
              this.canvas.add(img);
            });
          });
        })
        .catch((error) => {
          this.loading_gen_image = false;
          console.error("Error loading images:", error);
        });
    },
    load_images_xxx() {
      this.loading_gen_image = true;
      this.$http({
        method: "POST",
        url: `/product/gen-image-kit`,
        data: {
          images: this.images,
        },
      })
        .then((response) => {
          this.loading_gen_image = false;
          const images = response.data.data;
          images.forEach((image, index) => {
            const base64Image = `data:${image.contentType};base64,${image.data}`;
            fabric.Image.fromURL(base64Image, (img) => {
              img.scale(0.5); // Scale the image to fit within 400x400
              img.set({
                left: img.left * 0.5 || 0,
                top: img.top * 0.5 || 0,
              });
              this.canvas.add(img);
            });
          });
        })
        .catch((error) => {
          this.loading_gen_image = false;
          console.error("Error loading images:", error);
        });
    },
    rotateSelectedObject() {
      const activeObject = this.canvas.getActiveObject();
      if (activeObject) {
        const center = activeObject.getCenterPoint();
        activeObject.set({
          left: center.x,
          top: center.y,
          originX: "center",
          originY: "center",
        });
        activeObject.rotate((activeObject.angle + 90) % 360);
        activeObject.setCoords();
        this.canvas.renderAll();
      } else {
        alert("No object selected to rotate.");
      }
    },

    bringToFront() {
      const activeObject = this.canvas.getActiveObject();
      if (activeObject) {
        activeObject.moveTo(this.canvas.getObjects().length - 1);

        const objects = this.canvas.getObjects();

        this.canvas.renderAll();

        const newIndex = this.canvas.getObjects().indexOf(activeObject);

        if (newIndex !== objects.length - 1) {
          console.error("Failed to move active object to the front.");
        }
      } else {
        alert("No object selected to bring to front.");
      }
    },

    sendToBack() {
      const activeObject = this.canvas.getActiveObject();
      if (activeObject) {
        activeObject.moveTo(0);
        const objects = this.canvas.getObjects();
        this.canvas.renderAll();

        const newIndex = this.canvas.getObjects().indexOf(activeObject);

        if (newIndex !== 0) {
          console.error("Failed to move active object to the back.");
        }
      } else {
        alert("No object selected to send to back.");
      }
    },

    addGuidelineWithLabel(y, labelText) {
      // Create the guideline
      const guideline = new fabric.Line([0, y, 400, y], {
        stroke: "red",
        strokeWidth: 1,
        selectable: false, // Make it non-selectable
        evented: false, // Disable interactions
        excludeFromExport: true, // Exclude from export
      });

      // Create the label above the guideline
      const label = new fabric.Text(labelText, {
        left: 10, // X-coordinate for the label
        top: y - 20, // Place it above the guideline
        fontSize: 14,
        fill: "blue",
        selectable: false, // Make it non-selectable
        evented: false, // Disable interactions
        excludeFromExport: true, // Exclude from export
      });

      // Add both to the canvas
      this.canvas.add(guideline);
      this.canvas.add(label);
    },

    saveCanvasAsImage() {
      try {
        // Temporarily adjust canvas to 800x800
        this.canvas.setWidth(800);
        this.canvas.setHeight(800);

        // Scale all objects up for high-resolution export
        this.canvas.getObjects().forEach((obj) => {
          obj.scaleX *= 2; // Scale back up to full resolution
          obj.scaleY *= 2;
          obj.left *= 2;
          obj.top *= 2;
          obj.setCoords();
        });

        this.canvas.renderAll();

        // Remove guidelines or non-exportable elements
        const objectsToHide = this.canvas
          .getObjects()
          .filter((obj) => obj.excludeFromExport);
        objectsToHide.forEach((obj) => {
          this.canvas.remove(obj);
        });

        // Export canvas to image
        const dataURL = this.canvas.toDataURL({
          format: "png",
          quality: 1.0,
        });

        // Restore hidden elements
        objectsToHide.forEach((obj) => {
          this.canvas.add(obj);
        });

        // Restore canvas back to 400x400 UI size
        this.canvas.setWidth(400);
        this.canvas.setHeight(400);
        this.canvas.getObjects().forEach((obj) => {
          obj.scaleX *= 0.5; // Scale back down for UI
          obj.scaleY *= 0.5;
          obj.left *= 0.5;
          obj.top *= 0.5;
          obj.setCoords();
        });

        this.canvas.renderAll();

        // Trigger download
        const link = document.createElement("a");
        link.href = dataURL;
        link.download = `${this.name || "canvas-image"}.png`;
        link.click();
      } catch (error) {
        console.error("Error saving canvas:", error.message);
        alert(
          "Saving failed due to a tainted canvas. Ensure all images support CORS."
        );
      }
    },

    saveCanvasAsImagexxxx() {
      try {
        // Temporarily adjust canvas size to 800x800 for saving
        this.canvas.setWidth(800);
        this.canvas.setHeight(800);
        this.canvas.getObjects().forEach((obj) => {
          obj.scaleX = obj.scaleX * 2;
          obj.scaleY = obj.scaleY * 2;
          obj.left = obj.left * 2;
          obj.top = obj.top * 2;
          obj.setCoords();
        });

        this.canvas.renderAll();

        const objects = this.canvas.getObjects();
        const objectsToHide = objects.filter((obj) => obj.excludeFromExport);
        objectsToHide.forEach((obj) => {
          this.canvas.remove(obj); // Remove guideline temporarily
        });

        const dataURL = this.canvas.toDataURL({
          format: "png", // or 'jpeg'
          quality: 1.0, // Only for 'jpeg'
        });

        // Restore the guideline after export
        objectsToHide.forEach((obj) => {
          this.canvas.add(obj); // Re-add guideline
        });

        const link = document.createElement("a");
        link.href = dataURL;
        link.download = `${this.name || "canvas-image"}.png`;
        link.click();

        // Restore canvas size and object scales after saving
        this.canvas.setWidth(400);
        this.canvas.setHeight(400);
        this.canvas.getObjects().forEach((obj) => {
          obj.scaleX = obj.scaleX * 0.5;
          obj.scaleY = obj.scaleY * 0.5;
          obj.left = obj.left * 0.5;
          obj.top = obj.top * 0.5;
          obj.setCoords();
        });

        this.canvas.renderAll();
      } catch (error) {
        console.error("Error saving canvas:", error.message);
        alert(
          "Saving failed due to a tainted canvas. Ensure all images support CORS."
        );
      }
    },
  },
};
</script>
